<c-callout class="bg-white:dark:bg-transparent" color="info">
  <ng-container *ngTemplateOutlet="default"></ng-container>
</c-callout>

<ng-template #default>
  <p *ngIf="!!name">
  An Angular {{name}} component{{plural ? 's' : ''}} {{plural ? 'have' : 'has'}} been created as a native Angular
  version
  of Bootstrap {{name}}. {{name}} {{plural ? 'are' : 'is'}} delivered with some new features,
  variants, and unique design that matches CoreUI Design System requirements.
  </p>

  <ng-content></ng-content>

  <br>
  For more information please visit our official <a href="{{href}}" target="_blank">documentation of CoreUI Components Library for Angular.</a>
</ng-template>
