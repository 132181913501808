<!-- <c-row>
    <c-col>
        <h2 cCardTitle class="mb-2"><strong class="text-primary">Config</strong></h2>
    </c-col>
</c-row> -->
<!-- <c-row class="mb-2">
    <c-col>
        <c-card>
            <c-card-body>
                <c-row>
                    <label cLabel="col" cCol [xl]="2" [lg]="3" for="inputUserName">
                        User Name :
                    </label>
                    <c-col [xl]="6" [lg]="5" class="mb-2">
                        <input #inputUserName cFormControl id="inputUserName" name="inputUserName" type="text"
                            [(ngModel)]="searchOptions.userName" />
                    </c-col>
                    <c-col [xl]="4" [lg]="4">
                        <div class="d-grid gap-2 d-md-flex">
                            <button (click)="onClickSearch()" class="me-md-2" cButton type="submit">Search</button>
                            <button (click)="onClickClear()" cButton color="secondary">Clear</button>
                        </div>
                    </c-col>
                </c-row>
            </c-card-body>
        </c-card>
    </c-col>
</c-row> -->
<c-row>
    <c-col>
        <!-- <c-card> -->
            <!-- <c-card-body> -->
                <!-- <c-row class="mb-2">
            <c-col>
              <div class="d-grid gap-2 d-md-flex">
                <button (click)="onClickAddCondition()" *ngIf="actionBtnOptions.addCondition" class="me-md-2" cButton
                  type="submit" color="dark">Add New Condition</button>
                <input hidden id="browseAboCsv" name="browseAboCsv" class="input-hide"
                  (change)="changeSelectedFile($event)" type="file" [accept]="allowedTypeFile" />
                <button class="me-2" (click)="clickBrowesFileBtn('browseAboCsv')" cButton type="submit">Upload ABO
                  csv</button>
                <button class="me-2" cButton type="submit">Refresh</button>
              </div>
            </c-col>
          </c-row> -->
                <c-row class="mb-2">
                    <c-col>
                        <ag-grid-angular domLayout="autoHeight" class="ag-theme-alpine" [rowHeight]="50"
                            [paginationPageSize]="10" [columnDefs]="ruleColumnDefs" [frameworkComponents]="frameworkComponents"
                            [rowData]="configurationList" [enableCellTextSelection]="true" [enableRangeSelection]="true"
                            (gridReady)="onGridReady($event)" [suppressRowClickSelection]="true"
                            [pagination]="true">
                        </ag-grid-angular>
                    </c-col>
                </c-row>
                <c-row>
                    <c-col>
                        <!-- <app-paging-tool [pageinfo]="pageInfo" (onClickPage)="gotoPage($event)">
                        </app-paging-tool> -->
                    </c-col>
                </c-row>
            <!-- </c-card-body> -->
        <!-- </c-card> -->
    </c-col>
</c-row>
<!-- alignment="center" -->
<c-modal [visible]="asingRoleConditionModal" backdrop="static" id="asingRoleConditionModalId" class="modal fade"
    tabindex="-1">
    <c-modal-header>
        <h5 cModalTitle><span style="color: green;">EDIT : </span> Config
        </h5>
        <button (click)="closeAsingRoleConditionModal()" cButtonClose></button>
    </c-modal-header>
    <c-modal-body>

        <c-row class="mb-3">
            <label cLabel="col" cCol [sm]="4" for="inputUid">
                uId :
            </label>
            <c-col [sm]="8" class="mb-2">
                <input cFormControl id="inputUid" name="inputUid" type="text" [disabled]="true"
                    [(ngModel)]="configuration.uId" />
            </c-col>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" cCol [sm]="4" for="inputConfigNameTh">
                ConfigNameTh :
            </label>
            <c-col [sm]="8" class="mb-2">
                <input cFormControl id="inputConfigNameTh" name="inputConfigNameTh" type="text"
                    [disabled]="true" [(ngModel)]="configuration.configNameTh" />
            </c-col>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" cCol [sm]="4" for="inputConfigGroup">
                ConfigGroup :
            </label>
            <c-col [sm]="8" class="mb-2">
                <input cFormControl id="inputConfigGroup" name="inputConfigGroup" type="text"
                    [disabled]="true" [(ngModel)]="configuration.configGroup" />
            </c-col>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" cCol [sm]="4" for="inputConfigCode">
                ConfigCode :
            </label>
            <c-col [sm]="8" class="mb-2">
                <input cFormControl id="inputConfigCode" name="inputConfigCode" type="text"
                    [disabled]="true" [(ngModel)]="configuration.configCode" />
            </c-col>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" cCol [sm]="4" for="inputConfigValue">
                ConfigValue :
            </label>
            <c-col [sm]="8" class="mb-2">
                <input cFormControl id="inputConfigValue" name="inputConfigValue" type="text" [(ngModel)]="configuration.configValue" />
            </c-col>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" cCol [sm]="4" for="selectStatus">
                Status :
            </label>
            <c-col [sm]="8" class="mb-2">
                <select name="selectStatus" class="form-select" aria-label="selectStatus"
                    [(ngModel)]="selectedStatus" cSelect>
                    <option *ngFor="let status of configStatus" [value]="status">
                        {{ status}}
                    </option>
                </select>
            </c-col>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" cCol [sm]="4" for="calendarEffectiveDate">
                Effective Datetime :
            </label>
            <c-col [sm]="8" class="mb-2">
                <p-calendar styleClass="p-form-control" [readonlyInput]="true"[id]="'calendarEffectiveDate'" [(ngModel)]="configEffectiveDatetime" [showIcon]="true" [showTime]="true" [showSeconds]="true" [dateFormat]="'dd/mm/yy'" [showButtonBar]="true" [defaultDate]="defaultDateEffective" [showClear]="true"></p-calendar>
            </c-col>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" cCol [sm]="4" for="calendarExpireDate">
                Expire Datetime :
            </label>
            <c-col [sm]="8" class="mb-2">
                 <p-calendar styleClass="p-form-control"  [readonlyInput]="true" [id]="'calendarExpireDate'" [(ngModel)]="configExpireDatetime" [showIcon]="true" [showTime]="true" [showSeconds]="true" [dateFormat]="'dd/mm/yy'" [showButtonBar]="true" [defaultDate]="defaultDateExpire" [showClear]="true"></p-calendar>
            </c-col>
        </c-row>


    </c-modal-body>
    <c-modal-footer>
        <button (click)="saveCondition()" cButton color="primary" [disabled]="!isValidAbo">Save</button>
        <button (click)="closeAsingRoleConditionModal()" cButton color="secondary">
            Cancel
        </button>
    </c-modal-footer>
</c-modal>

<ngx-spinner bdOpacity="0.1" bdColor="rgba(51,51,51,0.5)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p class="text-white">Loading...</p>
</ngx-spinner>
