import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectOptionModel } from '../models/common/common.model';

import { EventEmitter, Injectable, Output } from "@angular/core";
import { HeaderNavigateModel } from '../models/common/headernavigate.model';


@Injectable({
    providedIn: 'root'
  })
export class HeaderNavigateService {
    constructor(
        private http: HttpClient
    ) {}

    @Output() setNavigateEvent = new EventEmitter<string>();

    setNavigate(headerNavigateModelList:Array<HeaderNavigateModel>) {
        /*this.items = [
            { label: 'Home', url: '/', attributes: { title: 'Home' } },
            { label: 'Library', url: '/' },
            { label: 'Data', url: '/dashboard/' },
            { label: 'CoreUI', url: '/' }
          ];*/
        this.setNavigateEvent.emit(JSON.stringify(headerNavigateModelList)); 
    }
}