import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { SelectOptionModel } from 'src/app/core/models/common/common.model';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { DemoRoleService } from 'src/app/core/services/demorole.service';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { UserService } from 'src/app/core/services/user.service';
import { DemoRoleStatus, DemoRole } from 'src/app/core/entity/demorole';
import { EditBtnCellRenderer } from '../components/edit-button-cell-renderer.component';
import { DateTimeRenderer } from '../components/datetime-cell-renderer.component';

declare var $: any;

interface SearchFormModel {
  roleName: string;
  roleStatus: string;
}

interface AddFormModel {
  roleName: string;
  roleStatus: string;
}

interface EditFormModel {
  roleId: number | undefined;
  roleName: string | undefined;
  roleStatus: string | undefined;
}

@Component({
  selector: 'app-demorole',
  templateUrl: './demorole.component.html',
  styleUrls: ['./demorole.component.scss'],
})
export class DemoroleComponent implements OnInit {
  accessToken: string;

  roleStatusOptionList: Array<SelectOptionModel> = [
    {
      value: DemoRoleStatus.STATUS_ACTIVE,
      label: DemoRoleStatus.STATUS_ACTIVE,
    },
    {
      value: DemoRoleStatus.STATUS_INACTIVE,
      label: DemoRoleStatus.STATUS_INACTIVE,
    },
  ];
  /*############## Data Grid View ##############*/
  frameworkComponents = {
    editBtnCellRenderer: EditBtnCellRenderer,
    dateTimeRenderer: DateTimeRenderer,
  };
  /*
  {
    "id": 1,
    "name": "Admin",
    "status":"ACTIVE",
    "isDeleted": false,
    "createdDate": "2022-01-03T16:49:54.856904",
    "createdBy": "SYSTEM",
    "modifiedDate": "2022-01-03T16:49:54.856904",
    "modifiedBy": "SYSTEM"
  }
  */

  searchColumnDefs: ColDef[] = [
    { field: 'id', sortable: true, filter: true, maxWidth: 50 },
    {
      headerName: 'action',
      field: 'id',
      cellRenderer: 'editBtnCellRenderer',
      cellRendererParams: {
        clicked: (field: any) => {
          this.openEditRoleModal(field);
          //alert(`${field} was clicked`);
        },
      },
      maxWidth: 100,
    },
    { field: 'name', sortable: true, filter: true, maxWidth: 150 },
    { field: 'status', sortable: true, filter: true, maxWidth: 150 },
    { field: 'isDeleted', sortable: true, filter: true, maxWidth: 150 },
    {
      field: 'createdDate',
      sortable: true,
      filter: true,
      cellRenderer: 'dateTimeRenderer',
    },
    { field: 'createdBy', sortable: true, filter: true, maxWidth: 150 },
    {
      field: 'modifiedDate',
      sortable: true,
      filter: true,
      cellRenderer: 'dateTimeRenderer',
    },
    { field: 'modifiedBy', sortable: true, filter: true },
  ];

  searchRowData: Array<any> = [];
  /*searchRowData: Array<any> = [
      { id: 1, name: 'Admin', staus: 'ACTIVE', isDeleted:false, createdDate:"2022-01-03T16:49:54.856904",
        "createdBy":"SYSTEM",  "modifiedDate": "2022-01-03T16:49:54.856904","modifiedBy": "SYSTEM"}
  ];*/
  private searchGridApi: any;

  addDataErrorMsg: string = '';
  editDataErrorMsg: string = '';

  /*############## FormModel ##############*/
  searchFormModel: SearchFormModel = {
    roleName: '',
    roleStatus: '',
  };

  addFormModel: AddFormModel = {
    roleName: '',
    roleStatus: '',
  };

  editFormModel: EditFormModel = {
    roleId: undefined,
    roleName: '',
    roleStatus: '',
  };
  editDemoRole: DemoRole | undefined = undefined;

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private headerNavigateService: HeaderNavigateService,
    private demoRoleService: DemoRoleService
  ) {

    this.accessToken = userService.getAuthUser().access_token || '';
  }

  ngOnInit(): void {
    this.initialData();
  }

  ngAfterViewInit() {}

  initialData() {
    let headerNavigateModelList: Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'Demo Role', url: '/demorole' },
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);

    this.demoRoleService
      .searchDemoRole(
        this.accessToken,
        this.searchFormModel.roleName,
        this.searchFormModel.roleStatus
      )
      .subscribe({
        next: (data) => {
          this.searchRowData = [];
          let response = data as Array<DemoRole>;
          this.searchRowData.push(...response);
        },
        error: (error) => {
          console.error(JSON.stringify(error));
          this.alertService.alertError(
            "Can't search , " + JSON.stringify(error)
          );
        },
      });
  }

  checkLogOut(error: any) {
    if (error.status == 401) {
      this.userService.logout();
    }
  }

  search() {
    this.searchRowData = [];
    this.demoRoleService
      .searchDemoRole(
        this.accessToken,
        this.searchFormModel.roleName,
        this.searchFormModel.roleStatus
      )
      .subscribe({
        next: (data) => {
          this.searchRowData = [];
          let response = data as Array<DemoRole>;

          this.searchRowData.push(...response);

          //this.searchGridApi.setColumnDefs([]);
          this.searchGridApi.setRowData([]);

          //this.searchGridApi.setColumnDefs(this.columnDefs);
          this.searchGridApi.setRowData(this.searchRowData);
        },
        error: (error) => {
          console.error(JSON.stringify(error));
          this.alertService.alertError(
            "Can't search , " + JSON.stringify(error)
          );
        },
      });
  }

  onSearchGridReady(params: any) {
    this.searchGridApi = params.api; // To access the grids API
  }

  openAddRoleModal() {
    this.addFormModel = {
      roleName: '',
      roleStatus: DemoRoleStatus.STATUS_ACTIVE,
    };
    $('#add_role_modal').modal('show');
  }

  openEditRoleModal(id: number) {
    this.editDemoRole = undefined;
    this.editFormModel = {
      roleId: undefined,
      roleName: '',
      roleStatus: DemoRoleStatus.STATUS_ACTIVE,
    };
    $('#edit_role_modal').modal('show');
    this.demoRoleService.getDemoRole(this.accessToken, id).subscribe({
      next: (data) => {
        let response = data as DemoRole;
        if (response == null) {
          this.editDataErrorMsg = 'Not found role [' + id + ']';
        } else {
          this.editDemoRole = response;

          this.editFormModel = {
            roleId: response.id,
            roleName: response.name,
            roleStatus: response.status,
          };
        }
      },
      error: (error) => {
        console.error(JSON.stringify(error));
        this.addDataErrorMsg =
          "Can't get role [" + id + '], ' + JSON.stringify(error);
        //this.alertService.alertError("Can't insert demoRole , "+ JSON.stringify(error));
      },
    });
  }

  addRole() {
    this.demoRoleService
      .addDemoRole(
        this.accessToken,
        this.addFormModel.roleName,
        this.addFormModel.roleStatus
      )
      .subscribe({
        next: (data) => {
          this.alertService.alertSuccess('Insert success');
          $('#add_role_modal').modal('hide');

          // refresh data
          this.search();
        },
        error: (error) => {
          console.error(JSON.stringify(error));
          this.addDataErrorMsg = "Can't insert , " + JSON.stringify(error);
          //this.alertService.alertError("Can't insert demoRole , "+ JSON.stringify(error));
        },
      });
  }

  editRole() {
    this.demoRoleService
      .updateDemoRole(
        this.accessToken,
        this.editFormModel.roleId,
        this.editFormModel.roleName,
        this.editFormModel.roleStatus
      )
      .subscribe({
        next: (data) => {
          this.alertService.alertSuccess('Update success');
          $('#edit_role_modal').modal('hide');

          // refresh data
          this.search();
        },
        error: (error) => {
          console.error(JSON.stringify(error));
          this.addDataErrorMsg = "Can't update , " + JSON.stringify(error);
          //this.alertService.alertError("Can't insert demoRole , "+ JSON.stringify(error));
        },
      });
  }
}
