import { DatePipe } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

// https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/
// https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/
@Component({
  selector: 'count-cell-renderer',
  template: ` <label cLabel style="color: {{ vColor }}">{{ value }}</label>`,
})
export class CountCellRenderer implements ICellRendererAngularComp, OnDestroy {
  public value: any;
  public vColor: any;
  pipe = new DatePipe('en-US');

  agInit(params: ICellRendererParams): void {
    ///////////////////////////////////////////////////////// Random Round 1
    if (params.data.upline.length >= 1) {
      if (params.colDef?.field == 'upline[0].aboNumber') {
        this.value = params.data.upline[0].aboNumber;
      } else if (params.colDef?.field == 'upline[0].randomDateTime') {
        this.value = this.pipe.transform(params.data.upline[0].randomDateTime, 'dd/MM/yyyy HH:mm');
      }
 
      if (params.colDef?.field == 'upline[0].aboNumber' || params.colDef?.field == 'upline[0].randomDateTime') {
        if (params.data.paidStatus == 'waiting') {
          this.vColor = '#000000'; // Black
        } else if ((params.data.paidStatus == 'paid') && params.data.upline[0].acceptStatus == null) {
          this.vColor = '#0000CD'; // MediumBlue
        } else if ((params.data.paidStatus == 'paid') && params.data.upline[0].acceptStatus == 'notaccept') {
          this.vColor = '#A9A9A9'; //DarkGrey
        } else if ((params.data.paidStatus == 'paid') && (params.data.upline[0].acceptStatus == 'accepted' || params.data.upline[0].acceptStatus == 'autoaccept')) {
          this.vColor = '#008000'; //Green
        }
      }
    }
    ///////////////////////////////////////////////////////////// Randon Round 2
    if (params.data.upline.length >= 2) {
      if (params.colDef?.field == 'upline[1].aboNumber') {
        this.value = params.data.upline[1].aboNumber;
      } else if (params.colDef?.field == 'upline[1].randomDateTime') {
        this.value = this.pipe.transform(params.data.upline[1].randomDateTime, 'dd/MM/yyyy HH:mm');
      }
      if (params.colDef?.field == 'upline[1].aboNumber' || params.colDef?.field == 'upline[1].randomDateTime') {
        if (params.data.upline[1].acceptStatus == null) {
          this.vColor = '#0000CD'; // MediumBlue
        } else if (params.data.upline[1].acceptStatus == 'notaccept') {
          this.vColor = '#A9A9A9'; //DarkGrey
        } else if (params.data.upline[1].acceptStatus == 'accept' || params.data.upline[1].acceptStatus == 'autoaccept') {
          this.vColor = '#008000'; //Green
        }
      }
    }
    if (params.data.upline.length >= 3) {
      ///////////////////////////////////////////////////////////// Randon Round 3
      if (params.colDef?.field == 'upline[2].aboNumber') {
        this.value = params.data.upline[2].aboNumber;
      } else if (params.colDef?.field == 'upline[2].randomDateTime') {
        this.value = this.pipe.transform(params.data.upline[2].randomDateTime, 'dd/MM/yyyy HH:mm');
      }
      if (params.colDef?.field == 'upline[2].aboNumber' || params.colDef?.field == 'upline[2].randomDateTime') {
        if (params.data.upline[2].randomCnt == 3 && params.data.upline[2].acceptStatus == null) {
          this.vColor = '#008000'; //Green
        } else if (params.data.upline[2].randomCnt == 3 && params.data.upline[2].acceptStatus == 'notaccept') {
          this.vColor = '#D3D3D3'; //LightGrey
        } else if (params.data.upline[2].randomCnt == 3 && (params.data.upline[2].acceptStatus == 'accept' || params.data.upline[2].acceptStatus == 'autoaccept')) {
          this.vColor = '#008000'; //Green
        }
      }
    }
  }

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
