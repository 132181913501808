<c-row ngPreserveWhitespaces>
  <c-col xs="12">
    <c-card class="mb-4">
      <c-card-header>
        <strong>Dashboard</strong>
      </c-card-header>
      <c-card-body>
        <!--
            <table cTable>
                <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Class</th>
                  <th scope="col">Heading</th>
                  <th scope="col">Heading</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td colSpan="2">Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
                </tbody>
              </table>
              -->
      </c-card-body>
    </c-card>
  </c-col>
</c-row>
