<!--<c-footer>-->
<div>
  <!--
    <a href="#" target="_blank">IAGroup</a>
    -->
  <span>Amway(Thailand) @ 2022 ABO Pool System</span>
</div>
<!--
  <div class="ms-auto">
    Powered by
    <a href="https://coreui.io/pro/angular" target="_blank">
      <span> CoreUI v4 for Angular</span>
    </a>
  </div>
  -->
<!--</c-footer>-->
