import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    AccordionModule,
    BadgeModule,
    BreadcrumbModule,
    ButtonModule,
    CardModule,
    CarouselModule,
    CollapseModule,
    DropdownModule,
    FormModule,
    GridModule,
    ListGroupModule,
    NavModule,
    PaginationModule,
    PopoverModule,
    ProgressModule,
    SharedModule,
    SpinnerModule,
    TableModule,
    TabsModule,
    TooltipModule,
    UtilitiesModule,
    WidgetModule,
    AlertModule,
    ModalModule,
    ToastModule,
    ButtonGroupModule,
    AvatarModule,
    NavbarModule,
    FooterModule,
    HeaderModule,
    SidebarModule,


} from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { ChartjsModule } from '@coreui/angular-chartjs';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {CalendarModule} from 'primeng/calendar';

@NgModule({
    declarations: [ ],
    imports: [
        CommonModule,
        FormsModule,
        AccordionModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        CarouselModule,
        CollapseModule,
        DropdownModule,
        FormModule,
        GridModule,
        ListGroupModule,
        NavModule,
        PaginationModule,
        PopoverModule,
        ProgressModule,
        SharedModule,
        SpinnerModule,
        TableModule,
        TabsModule,
        TooltipModule,
        UtilitiesModule,
        WidgetModule,
        AlertModule,
        ModalModule,
        ToastModule,
        ButtonGroupModule,
        AvatarModule,
        NavbarModule,
        FooterModule,
        HeaderModule,
        SidebarModule,
        IconModule,
        ChartjsModule,
        AgGridModule,
        NgxSpinnerModule,
        NgbModule,
        CalendarModule
    ],
    exports: [
        FormsModule,
        AccordionModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CardModule,
        CarouselModule,
        CollapseModule,
        DropdownModule,
        FormModule,
        GridModule,
        ListGroupModule,
        NavModule,
        PaginationModule,
        PopoverModule,
        ProgressModule,
        SharedModule,
        SpinnerModule,
        TableModule,
        TabsModule,
        TooltipModule,
        UtilitiesModule,
        WidgetModule,
        AlertModule,
        ModalModule,
        ToastModule,
        ButtonGroupModule,
        AvatarModule,
        NavbarModule,
        FooterModule,
        HeaderModule,
        SidebarModule,
        IconModule,
        ChartjsModule,
        AgGridModule,
        NgxSpinnerModule,
        NgbModule,
        CalendarModule
    ],
    schemas: []
})
export class ShareModule { }
