<c-row>
    <c-col >
      <c-card class="mb-3">
        <c-card-header>Demo Role <button (click)="openAddRoleModal()" [color]="'secondary'" cButton class="shadow">Create</button></c-card-header>
        <c-card-body>
            <h5 cCardTitle>Search Form</h5>
            <form cForm>
            
                <c-row class="mb-3">
                  <label [sm]="2" cCol cLabel="col" for="search_role_name">
                    Role Name
                  </label>
                  <c-col [sm]="10">
                    <input cFormControl name="role_name" id="search_role_name" type="text" [(ngModel)]="searchFormModel.roleName" />
                  </c-col>
                </c-row>
                <c-row class="mb-3">
                  <label cLabel="col" class="col-sm-2" for="search_role_status">
                    Role Status
                  </label>
                  <c-col [sm]="10">
                    <select name="search_role_status" class="form-select" aria-label="search_role_status" [(ngModel)]="searchFormModel.roleStatus">
                      <option value=""></option>  
                      <option *ngFor="let option of roleStatusOptionList" value="{{option.value}}">{{option.label}}</option>
                    </select>
                  </c-col>
                </c-row>
                <c-row class="mb-3">
                  <label cLabel="col" class="col-sm-2" >
                    
                  </label>
                  <c-col [sm]="10">
                    <input (click)="search()" name="search_search_btn" cButton color="secondary" type="button" value="Search" class="px-4" style="margin-right: 0.5rem;">
                    <!--
                    <input [routerLink]="['/campaignrole']" name="back_btn" cButton color="secondary" type="button" value="Back" class="px-4">
                    -->
                  </c-col>
                </c-row>
            </form>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
  
  <c-row>
    <c-col >
      <c-card class="mb-3">
        <c-card-header>Result</c-card-header>
        <c-card-body>
            <!--
            <ag-grid-angular
                style="width: 70vw; height: 50vh;"
                class="ag-theme-alpine"
                [rowData]="searchRowData"
                [columnDefs]="searchColumnDefs"
                (gridReady)="onSearchGridReady($event)"
            >
            </ag-grid-angular>
            -->
            <ag-grid-angular
                style="width: 100%; height: 50vh;"
                class="ag-theme-alpine"
                [rowData]="searchRowData"
                [columnDefs]="searchColumnDefs"
                (gridReady)="onSearchGridReady($event)"
                [frameworkComponents]='frameworkComponents'
            >
            </ag-grid-angular>
        </c-card-body>
    </c-card>
  </c-col>
</c-row>



<!-- add data modal -->
<div class="modal fade" id="add_role_modal" tabindex="-1" aria-labelledby="Add Role Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable" style="max-width: 80%;">
        <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">Add Data</h5>
            <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <div *ngIf="addDataErrorMsg != ''" class="danger">
                {{addDataErrorMsg}}
            </div>
            <div class="table_data_modal" style="background-color: white;" aria-hidden="true">
                <c-row>
                    <c-col>
                        <c-card class="mb-3">
                            <c-card-header style="">
                                <div style="position: relative;display: inline-block;">
                                
                                </div>
                                <div style="position: relative;display: inline-block;float: right;">
                                    <!--
                                    <input (click)="addCustomField()" cButton color="success" type="button" value="Add Field" class="px-4" style="margin-right: 0.5rem;" 
                                        >
                                        -->
                                </div>
                            </c-card-header>
                            <c-card-body>
                              <form cForm>
            
                                <c-row class="mb-3">
                                  <label [sm]="2" cCol cLabel="col" for="add_role_name">
                                    Role Name
                                  </label>
                                  <c-col [sm]="10">
                                    <input cFormControl name="add_role_name" id="add_role_name" type="text" [(ngModel)]="addFormModel.roleName" />
                                  </c-col>
                                </c-row>
                                <c-row class="mb-3">
                                  <label cLabel="col" class="col-sm-2" for="add_role_status">
                                    Role Status
                                  </label>
                                  <c-col [sm]="10">
                                    <select name="add_role_status" class="form-select" aria-label="add_role_status" [(ngModel)]="addFormModel.roleStatus">
                                        <option *ngFor="let option of roleStatusOptionList" value="{{option.value}}">{{option.label}}</option>
                                    </select>
                                  </c-col>
                                </c-row>
                                <c-row class="mb-3">
                                  <label cLabel="col" class="col-sm-2" >
                                    
                                  </label>
                                  <c-col [sm]="10">
                                    <input (click)="addRole()" name="add_save_btn" cButton color="primary" type="button" value="Save" class="px-4" style="margin-right: 0.5rem;">
                                    <!--
                                    <input [routerLink]="['/campaignrole']" name="back_btn" cButton color="secondary" type="button" value="Back" class="px-4">
                                    -->
                                  </c-col>
                                </c-row>
                              </form>

                            </c-card-body>
                        </c-card>
                    </c-col>
                </c-row>

            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
         
        </div>
      </div>
    </div>
  </div>


  
<!-- edit data modal -->
<div class="modal fade" id="edit_role_modal" tabindex="-1" aria-labelledby="Edit Role Modal" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable" style="max-width: 80%;">
      <div class="modal-content">
      <div class="modal-header">
          <h5 class="modal-title">Edit Data</h5>
          <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
          <div *ngIf="editDataErrorMsg != ''" class="danger">
              {{editDataErrorMsg}}
          </div>
          <div class="table_data_modal" style="background-color: white;" aria-hidden="true">
              <c-row>
                  <c-col>
                      <c-card class="mb-3">
                          <c-card-header style="">
                              <div style="position: relative;display: inline-block;">
                              
                              </div>
                              <div style="position: relative;display: inline-block;float: right;">
                                  <!--
                                  <input (click)="addCustomField()" cButton color="success" type="button" value="Add Field" class="px-4" style="margin-right: 0.5rem;" 
                                      >
                                      -->
                              </div>
                          </c-card-header>
                          <c-card-body>
                            <form cForm>
                              <c-row class="mb-3">
                                <label [sm]="2" cCol cLabel="col" for="edit_role_id">
                                  Role Id
                                </label>
                                <c-col [sm]="10">
                                  <input cFormControl name="edit_role_id" id="edit_role_id" type="text" plaintext readonly value="{{editFormModel.roleId}}" />
                                </c-col>
                              </c-row>
                              <c-row class="mb-3">
                                <label [sm]="2" cCol cLabel="col" for="edit_role_name">
                                  Role Name
                                </label>
                                <c-col [sm]="10">
                                  <input cFormControl name="edit_role_name" id="edit_role_name" type="text" [(ngModel)]="editFormModel.roleName" />
                                </c-col>
                              </c-row>
                              <c-row class="mb-3">
                                <label cLabel="col" class="col-sm-2" for="edit_role_status">
                                  Role Status
                                </label>
                                <c-col [sm]="10">
                                  <select name="edit_role_status" class="form-select" aria-label="edit_role_status" [(ngModel)]="editFormModel.roleStatus">
                                      <option *ngFor="let option of roleStatusOptionList" value="{{option.value}}">{{option.label}}</option>
                                  </select>
                                </c-col>
                              </c-row>
                              <c-row class="mb-3">
                                <label cLabel="col" class="col-sm-2" >
                                  
                                </label>
                                <c-col [sm]="10">
                                  <input (click)="editRole()" name="edit_save_btn" cButton color="primary" type="button" value="Save" class="px-4" style="margin-right: 0.5rem;">
                                  <!--
                                  <input [routerLink]="['/campaignrole']" name="back_btn" cButton color="secondary" type="button" value="Back" class="px-4">
                                  -->
                                </c-col>
                              </c-row>
                            </form>

                          </c-card-body>
                      </c-card>
                  </c-col>
              </c-row>

          </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
       
      </div>
    </div>
  </div>
</div>