import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';

import { DefaultLayoutComponent } from './containers';
import { Page404Component } from './views/pages/page404/page404.component';
import { Page500Component } from './views/pages/page500/page500.component';
//import { LoginComponent } from './views/pages/login/login.component';

import { RegisterComponent } from './views/pages/register/register.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { LoginComponent } from './modules/login/login.component';

import { AuthGuard } from './core/services/auth-guard.service';
import { CampaignroleComponent } from './modules/campaignrole/campaignrole.component';
import { CampaignroleCreateComponent } from './modules/campaignrole/campaignrole-create/campaignrole-create.component';
import { CampaignroleDetailComponent } from './modules/campaignrole/campaignrole-detail/campaignrole-detail.component';
import { DemoroleComponent } from './modules/demorole/demorole.component';
import { NewRandomComponent } from './modules/newrandom/newrandom.component';
import { RetryrandomComponent } from './modules/retryrandom/retryrandom.component';
import { HistoryrandomComponent } from './modules/historyrandom/historyrandom.component';
import { PooldataComponent } from './modules/pooldata/pooldata.component';
import { ApilogComponent } from './modules/apilog/apilog.component';
import { ConfigComponent } from './modules/config/config.component';
import { ImportpooldataComponent } from './modules/importpooldata/importpooldata.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  /*
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      { path: 'dashboard', component: DashboardComponent },
    ]
  },
*/

  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'demorole',
        component: DemoroleComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'newrandom',
        component: NewRandomComponent,
        //canActivate: [AuthGuard],
      },
      {
        path: 'retryrandom',
        component: RetryrandomComponent,
        //canActivate: [AuthGuard],
      },
      {
        path: 'historyrandom',
        component: HistoryrandomComponent,
        //canActivate: [AuthGuard],
      },
      {
        path: 'pooldata',
        component: PooldataComponent,
        //canActivate: [AuthGuard],
      },
      {
        path: 'apilog',
        component: ApilogComponent,
        //canActivate: [AuthGuard],
      },
      {
        path: 'config',
        component: ConfigComponent,
        //canActivate: [AuthGuard],
      },
      {
        path: 'importpooldata',
        component: ImportpooldataComponent,
        //canActivate: [AuthGuard],
      },

      //{ path: "campaignrole",component: CampaignroleComponent, canActivate:[AuthGuard]},
      //{ path: "campaignrole-create",component: CampaignroleCreateComponent, canActivate:[AuthGuard]},
      //{ path: "campaignrole/:id",component: CampaignroleDetailComponent, canActivate:[AuthGuard]},

      {
        path: 'dashboard_prototype',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'theme',
        loadChildren: () =>
          import('./views/theme/theme.module').then((m) => m.ThemeModule),
      },
      {
        path: 'base',
        loadChildren: () =>
          import('./views/base/base.module').then((m) => m.BaseModule),
      },
      {
        path: 'buttons',
        loadChildren: () =>
          import('./views/buttons/buttons.module').then((m) => m.ButtonsModule),
      },
      {
        path: 'forms',
        loadChildren: () =>
          import('./views/forms/forms.module').then((m) => m.CoreUIFormsModule),
      },
      {
        path: 'charts',
        loadChildren: () =>
          import('./views/charts/charts.module').then((m) => m.ChartsModule),
      },
      {
        path: 'icons',
        loadChildren: () =>
          import('./views/icons/icons.module').then((m) => m.IconsModule),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/widgets.module').then((m) => m.WidgetsModule),
      },
      {
        path: 'pages',
        loadChildren: () =>
          import('./views/pages/pages.module').then((m) => m.PagesModule),
      },
    ],
  },
  {
    path: '404',
    component: Page404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: Page500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page',
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page',
    },
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      anchorScrolling: 'enabled',
      initialNavigation: 'enabledBlocking',
      // relativeLinkResolution: 'legacy'
    }),
  ],
  //imports: [RouterModule.forRoot(routes), CommonModule],
  exports: [RouterModule],
})
export class AppRoutingModule { }