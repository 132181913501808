import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Apilog } from '../entity/apilog';

@Injectable({
  providedIn: 'root'
})
export class ApilogService {

  constructor(private http: HttpClient) { }

  private formatErrors(error: any) {
    return throwError(error);
  }

  listHistoryApi(accessToken: string, params: any){

    let requestDateFrom = '';
    let requestDateTo = '';
    
    if(params.dateFrom && params.dateTo){
      requestDateFrom = new Date((new Date(params.period || '').setDate(Number(params.dateFrom)))).toISOString();
      requestDateTo = new Date(new Date((new Date(params.period || '').setDate(Number(params.dateTo)))).setUTCHours(23, 59, 59, 999)).toISOString();
    }

    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${accessToken}`);

    const httpParams = new HttpParams()
      .set('period', params.period.replace('-', ''))
      .set('fromDateTime', requestDateFrom)
      .set('toDateTime', requestDateTo)
      .set('clientId', params.clientId)
      .set('appPath', params.apiPath)
      .set('requestId', params.requestId)
      .set('status', params.status.join(','))
      .set('page', '1')
      .set('rowPerPage', '100');

    return this.http
      .get(`${environment.api_url}/apibo/call/history-api?${httpParams.toString()}`, { headers: httpHeaders })
      .pipe(catchError(this.formatErrors));
  }
  
}
