<c-row>
    <c-col >
      <c-card class="mb-3">
        <c-card-header>Campaign Role <button [routerLink]="['/campaignrole-create']" [color]="'primary'" cButton class="shadow">Create</button></c-card-header>
        <c-card-body>
  
          <c-row>
            <c-col *ngFor="let item of campaignRoleList; index as i" [lg]="3">
              <c-card [color]="false?'warning':''" [textColor]="true?'':''" class="mb-3">
                <c-card-header>  </c-card-header>
                <c-card-body class="bg-gradient">
                  <h5 cCardTitle>{{item.name}}</h5>
                 
                  <p cCardText>
                    
                  </p>
                  <button [routerLink]="['/campaignrole', item.id]" [color]="false?'warning':'primary'" cButton class="shadow" style="margin-right: 0.5rem;">Detail</button>
                  <button (click)="delete(item)" color="danger" cButton class="shadow" style="margin-right: 0.5rem;">Delete</button>
                    
                </c-card-body>
              </c-card>
            </c-col>
          </c-row>
  
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>
  