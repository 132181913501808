import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';
import { AlertService } from 'src/app/core/services/alert.service';
import {
  AuthUser,
  User,
  profileUser,
  Permission,
} from 'src/app/core/models/auth/user.model';
import { LoginResponseModel } from 'src/app/core/models/auth/login.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  username: string = '';
  password: string = '';
  isWaiting: boolean = false;

  constructor(
    private userService: UserService,
    private alertService: AlertService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getToken();
  }

  login() {
    this.alertService.clearAlert();
    if (this.username == '' || this.username == undefined || this.username.length < 2) {
      this.alertService.alertError('Please input usename');
    } else if (this.password == '' || this.password == undefined || this.password.length < 2) {
      this.alertService.alertError('Please input password');
    } else {
      this.alertService.clearAlert();
      this.goToLogin();
    }
  }

  goToLogin() {
    if (this.isWaiting == true) {
      return;
    }
    this.isWaiting = true;
    this.userService.login(this.username, this.password).subscribe({
      next: (v: any) => {
        // navigate to home page
        let profileUser: profileUser = {
          profileName: v.data.profile.profileName,
          department: v.data.profile.department,
          fullName: v.data.profile.fullName,
          needChgPass: v.data.profile.needChgPass,
          position: v.data.profile.position,
          profilePic: v.data.profile.profilePic,
        };
        let permission = v.data.perm as Array<Permission>;

        this.userService.setProfileUser(profileUser);
        this.userService.setPermission(permission)
        this.router.navigate(['/']);
        this.isWaiting = false;
        this.alertService.clearAlert();
      },
      error: (e) => {
        let errMsg =
          'username/password ไม่ถูกต้อง (' + e.error.error.errorCode + ')';
        // let errMsg = e.message
        //   ? e.message
        //   : e.status
        //   ? `${e.status} - ${e.statusText}`
        //   : 'Server error';

        // console.error(errMsg);
        this.alertService.alertError(errMsg);
        this.isWaiting = false;
      },
      complete: () => {
        //console.info('complete');
        //this.alertService.alertSuccess("complete");
        this.isWaiting = false;
      },
    });
  }
  getToken() {
    this.userService.getjwtToken().subscribe({
      next: (v) => {
        let loginResponse = v as LoginResponseModel;
        console.log('v = ' + JSON.stringify(loginResponse));

        // set data to session
        let authUser: AuthUser = {
          access_token: loginResponse.jwtToken,
          username: this.username,
        };
        this.userService.setAuth(authUser);
      },
      error: (e) => {
        let errMsg = e.message
          ? e.message
          : e.status
            ? `${e.status} - ${e.statusText}`
            : 'Server error';

        console.error(errMsg);
        this.alertService.alertError(errMsg);
        this.isWaiting = false;
      },
      complete: () => {
        this.isWaiting = false;
      },
    });
  }
}