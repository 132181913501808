import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectOptionModel } from '../models/common/common.model';
import { Injectable } from '@angular/core';



@Injectable({
    providedIn: 'root'
  })
export class CampaignRoleService {
    constructor(
        private http: HttpClient
    ) {}


    /** ########## CampaignRole Api ############ **/
    private formatErrors(error: any) {
        return throwError(error);
    }
    
    /** List Campaign Role
     * GET /api/CampaignRoles 
     * response Array<CampaignRole>
     * [
        {
          "id": "approver",
          "name": "Approver",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": "channel",
          "name": "Channel",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": "editor",
          "name": "Editor",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": "executor",
          "name": "Model Executor",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": "owner",
          "name": "Campaign Owner",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": "viewer",
          "name": "Viewer",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        }
      ]
    */
    listCampaignRole(accessToken:string){
    
      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
      const body = {
        
      };
    
      return this.http
        .get(
          `${environment.api_url}/api/CampaignRoles`,
          { headers }
        )
        .pipe(catchError(this.formatErrors));
    }

    /**  Get Campaign Role
     * GET /api/CampaignRoles/{id} 
     * response CampaignRole
     * {
        "id": "viewer",
        "name": "Viewer",
        "isDeleted": false,
        "createdDate": "2022-01-03T16:49:54.856904",
        "createdBy": "SYSTEM",
        "modifiedDate": "2022-01-03T16:49:54.856904",
        "modifiedBy": "SYSTEM"
      }


    */
    getCampaignRole(accessToken:string, id:string | undefined){
    
      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
        const body = {
          
        };
      
        return this.http
          .get(
            `${environment.api_url}/api/CampaignRoles/${id}`,
            { headers }
          )
          .pipe(catchError(this.formatErrors));
    }

    /** 
     * Add Campaign Role
    POST /api/CampaignRoles/
    request
    {
      "id": "tester",
      "name": "Tester"
    }

    response
    {
      "id": "tester",
      "name": "Tester",
      "isDeleted": false,
      "createdDate": "2022-01-03T17:40:09.5077262+07:00",
      "createdBy": "user_approver1",
      "modifiedDate": "2022-01-03T17:40:09.5077262+07:00",
      "modifiedBy": "user_approver1"
    }

 */
    addCampaignRole(accessToken:string, id:string, name:string){
    
      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
        const body = {
            "id":id,
            "name": name
        };
      
        return this.http
          .post(
            `${environment.api_url}/api/CampaignRoles`,
            body,
            { headers }
          )
          .pipe(catchError(this.formatErrors));
    }

    /**
     * Update Campaign Role
     * PUT /api/CampaignRoles/{id}
     * *** ส่งเฉพาะ field ที่ต้องการ update ได้
     * request
     * {
        "name": "Tester Updated"
      }

        response
        {
          "id": "tester",
          "name": "Tester Updated",
          "isDeleted": false,
          "createdDate": "2022-01-03T17:40:09.507726",
          "createdBy": "user_approver1",
          "modifiedDate": "2022-01-03T17:40:44.3879578+07:00",
          "modifiedBy": "user_approver1"
        }


     * 
     */
    updateCampaignRole(accessToken:string, id:string | undefined,name:string){
    
      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
        const body = {
            "name": name
        };

        console.log(JSON.stringify(body));
      
        return this.http
          .put(
            `${environment.api_url}/api/CampaignRoles/${id}`,
            body,
            { headers }
          )
          .pipe(catchError(this.formatErrors));
    }
  
    /**
     * Delete Campaign Role
     * DELETE /api/CampaignRoles/{id}
        response
        {
        "id": "tester",
        "name": "Tester Updated",
        "isDeleted": true,
        "createdDate": "2022-01-03T17:40:09.507726",
        "createdBy": "user_approver1",
        "modifiedDate": "2022-01-03T17:41:17.3366481+07:00",
        "modifiedBy": "user_approver1"
      }

     */
    deleteCampaignRole(accessToken:string, id:string | undefined){

      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
        const body = {
           
        };
        
        return this.http
            .delete(
            `${environment.api_url}/api/CampaignRoles/${id}`,
            
            { headers }
            )
            .pipe(catchError(this.formatErrors));
    }
}