<div class="bg-light min-vh-100 d-flex flex-row align-items-center">
    <c-container>
        <c-row class="justify-content-center">
            <c-col class="col-auto">
                <c-card-group>
                    <c-card class="p-4">
                        <c-card-body>
                            <form cForm class="text-center">
                                <h1>Amway ABO Pool System</h1>
                                <p class="text-medium-emphasis">Sign In to your account</p>
                                <c-input-group class="mb-3">
                                    <span cInputGroupText>
                                        <svg cIcon name="cilUser"></svg>
                                    </span>
                                    <input name="username" autoComplete="username" cFormControl placeholder="Username" type="text" [(ngModel)]="username" required minlength="2" />
                                </c-input-group>
                                <c-input-group class="mb-4">
                                    <span cInputGroupText>
                                        <svg cIcon name="cilLockLocked"></svg>
                                    </span>
                                    <input name="password" autoComplete="current-password" cFormControl placeholder="Password" type="password" [(ngModel)]="password" required minlength="2" />
                                </c-input-group>
                                <c-row>
                                    <c-col>
                                        <button cButton class="btn w-100 btn-primary text-white" (click)="login()"
                                            [disabled]="username.length < 2 || password.length < 2">
                                            Login
                                        </button>
                                        <br />
                                        <img *ngIf="isWaiting" src="../../../assets/img/wait.gif" class="m-1" />
                                    </c-col>
                                    <!--
                    <c-col class="text-right" xs="6">
                      <button cButton class="px-0" color="link">
                        Forgot password?
                      </button>
                    </c-col>
                    -->
                                </c-row>
                            </form>
                        </c-card-body>
                    </c-card>
                    <!--
            <c-card [ngStyle]="{'width.%': 44}" class="text-white bg-primary py-5">
              <c-card-body class="text-center">
                <div>
                  <h2>Sign up</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                    tempor incididunt ut labore et dolore magna aliqua.
                  </p>
                  <button [active]="true" cButton class="mt-3" color="primary" routerLink="/register">
                    Register Now!
                  </button>
                </div>
              </c-card-body>
            </c-card>
            -->
                </c-card-group>
            </c-col>
        </c-row>
    </c-container>
</div>