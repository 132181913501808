<c-container>
    <c-row>
        <c-col class="col-6 mx-auto">
            <form cForm>
                <c-row class="mb-3">
                    <c-col class="col my-auto">
                        <button cButton class="btn btn-danger text-white w-100" (click)="show('TemRegis')">
                            Random Registration
                        </button>
                    </c-col>
                    <c-col class="col my-auto">
                        <button cButton class="btn btn-warning text-white w-100" (click)="show('TemCheckout')">
                            Random Checkout
                        </button>
                    </c-col>
                    <c-col class="col my-auto">
                        <button cButton class="btn btn-primary text-white w-100" (click)="show('TemConfirmpaid')">
                            Confirm PAID
                        </button>
                    </c-col>
                </c-row>
                <ng-template [ngIf]="TemRegis">
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Random Type :
                        </label>
                        <c-col class="col my-auto">
                            <c-form-check class="mb-0">
                                <input cFormCheckInput id="search_random_Type" type="radio" checked />
                                <label cFormCheckLabel for="search_random_Type">Registration</label>
                            </c-form-check>
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Period :
                        </label>
                        <c-col>
                            <!-- <input aria-label="search_period" placeholder="YYYYMM" type="text" name="search_r_period" id="search_r_period" [(ngModel)]="model.period" maxlength="6" cFormControl numbersOnly /> -->
                            <input class="form-control" type="month" aria-label="search_period" data-date-format="yyyyMM" id="search_r_period" name="search_r_period" [(ngModel)]="model.period">
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Province :
                        </label>
                        <c-col>
                            <input aria-label="search_province" type="text" name="search_r_province" id="search_r_province" [(ngModel)]="model.province" maxlength="2" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Postcode :
                        </label>
                        <c-col>
                            <input aria-label="search_postcode" type="text" name="search_r_postcode" id="search_r_postcode" [(ngModel)]="model.postCode" maxlength="5" cFormControl numbersOnly />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <c-col class="col-8" [offset]="4">
                            <c-row>
                                <c-col>
                                    <button cButton color="primary" class="w-100 text-white" (click)="random()">
                                        Random
                                    </button>
                                </c-col>
                                <c-col>
                                    <button cButton color="light" class="w-100" (click)="clear()">Clear</button>
                                </c-col>
                            </c-row>
                        </c-col>
                    </c-row>
                </ng-template>
                <ng-template [ngIf]="TemCheckout">
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Random Type :
                        </label>
                        <c-col class="col my-auto">
                            <c-form-check class="mb-0">
                                <input cFormCheckInput id="search_random_Type" type="radio" checked />
                                <label cFormCheckLabel for="search_random_Type">Public Checkout</label>
                            </c-form-check>
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Period :
                        </label>
                        <c-col>
                            <!-- <input aria-label="search_c_period" placeholder="YYYYMM" type="text" name="search_c_period" id="search_c_period" [(ngModel)]="model.period" maxlength="6" cFormControl numbersOnly /> -->
                            <input class="form-control" type="month" aria-label="search_period" id="search_c_period" name="search_c_period" [(ngModel)]="model.period">
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Province :
                        </label>
                        <c-col>
                            <input aria-label="search_province" type="text" name="search_c_province" id="search_c_province" [(ngModel)]="model.province" maxlength="2" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Postcode :
                        </label>
                        <c-col>
                            <input aria-label="search_c_postcode" type="text" name="search_c_postcode" id="search_c_postcode" [(ngModel)]="model.postCode" maxlength="5" cFormControl numbersOnly />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Request Dist Number :
                        </label>
                        <c-col>
                            <input aria-label="search_c_requestDistNumber" type="text" name="search_c_requestDistNumber" id="search_c_requestDistNumber" [(ngModel)]="model.requestDistNumber" maxlength="13" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Business Nature :
                        </label>
                        <c-col>
                            <select name="search_abo_type" class="form-select" aria-label="search_abo_type" [(ngModel)]="model.businessNature" cSelect>
                                <option *ngFor="let option of businessNatureOptionList" value="{{ option.value }}">
                                    {{ option.label }}
                                </option>
                            </select>
                            <!-- <input aria-label="search_c_businessNature" type="text" name="search_c_businessNature" id="search_c_businessNature" [(ngModel)]="model.businessNature" cFormControl /> -->
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Order No. :
                        </label>
                        <c-col>
                            <input aria-label="search_c_orderNumber" type="text" name="search_c_orderNumber" id="search_c_orderNumber" [(ngModel)]="model.orderNumber" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Order Value :
                        </label>
                        <c-col>
                            <input aria-label="search_c_orderValue" type="number" step='0.01' name="search_c_orderValue" (blur)="checkDigit()" placeholder="0.00" id="search_c_orderValue" [(ngModel)]="model.orderValue" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Order Date Time :
                        </label>
                        <c-col>
                            <!-- <input aria-label="search_c_orderDateTime" type="text" name="search_c_orderDateTime" id="search_c_orderDateTime" [(ngModel)]="model.orderDateTime" cFormControl /> -->
                            <input class="form-control" type="datetime-local" [(ngModel)]="model.orderDateTime" [ngModelOptions]="{standalone: true}">
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            PV :
                        </label>
                        <c-col>
                            <input aria-label="search_c_orderPv" type="text" name="search_c_orderPv" id="search_c_orderPv" [(ngModel)]="model.orderPv" cFormControl numbersOnly />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            BV :
                        </label>
                        <c-col>
                            <input aria-label="search_c_orderBv" type="text" name="search_c_orderBv" id="search_c_orderBv" [(ngModel)]="model.orderBv" cFormControl numbersOnly />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <c-col class="col-8" [offset]="4">
                            <c-row>
                                <c-col>
                                    <button cButton color="primary" class="w-100 text-white" (click)="random()">
                                        Random
                                    </button>
                                </c-col>
                                <c-col>
                                    <button cButton color="light" class="w-100" (click)="clear()">Clear</button>
                                </c-col>
                            </c-row>
                        </c-col>
                    </c-row>
                </ng-template>
                <ng-template [ngIf]="TemConfirmpaid">
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Random Id :
                        </label>
                        <c-col>
                            <input aria-label="search_randomId" type="text" name="search_p_randomId" id="search_p_randomId" [(ngModel)]="model.randomId" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Period :
                        </label>
                        <c-col>
                            <input class="form-control" type="month" aria-label="search_period" data-date-format="yyyyMM" id="search_period" name="search_period" [(ngModel)]="model.period">
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Request Dist Number :
                        </label>
                        <c-col>
                            <input aria-label="search_p_requestDistNumber" type="text" name="search_p_requestDistNumber" id="search_p_requestDistNumber" [(ngModel)]="model.requestDistNumber" maxlength="13" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Business Nature :
                        </label>
                        <c-col>
                            <select name="search_abo_type" class="form-select" aria-label="search_abo_type" [(ngModel)]="model.businessNature" cSelect>
                                <option *ngFor="let option of businessNatureOptionList" value="{{ option.value }}">
                                    {{ option.label }}
                                </option>
                            </select>
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Order No. :
                        </label>
                        <c-col>
                            <input aria-label="search_p_orderNumber" type="text" name="search_p_orderNumber" id="search_p_orderNumber" [(ngModel)]="model.orderNumber" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Order Value :
                        </label>
                        <c-col>
                            <input aria-label="search_p_orderValue" type="text" name="search_p_orderValue" id="search_p_orderValue" [(ngModel)]="model.orderValue" placeholder="0.00" cFormControl />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            Order Date Time :
                        </label>
                        <c-col>
                            <!-- <input aria-label="search_p_orderDateTime" type="text" name="search_p_orderDateTime" id="search_p_orderDateTime" [(ngModel)]="model.orderDateTime" cFormControl /> -->
                            <input class="form-control" type="datetime-local" [(ngModel)]="model.orderDateTime" [ngModelOptions]="{standalone: true}">
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            PV :
                        </label>
                        <c-col>
                            <input aria-label="search_p_orderPv" type="text" name="search_p_orderPv" id="search_p_orderPv" [(ngModel)]="model.orderPv" cFormControl numbersOnly />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                            BV :
                        </label>
                        <c-col>
                            <input aria-label="search_p_orderBv" type="text" name="search_p_orderBv" id="search_p_orderBv" [(ngModel)]="model.orderBv" cFormControl numbersOnly />
                        </c-col>
                    </c-row>
                    <c-row class="mb-3">
                        <c-col class="col-8" [offset]="4">
                            <c-row>
                                <c-col>
                                    <button cButton color="primary" class="w-100 text-white" (click)="random()">
                                        Confirm Paid
                                    </button>
                                </c-col>
                                <c-col>
                                    <button cButton color="light" class="w-100" (click)="clear()">Clear</button>
                                </c-col>
                            </c-row>
                        </c-col>
                    </c-row>
                </ng-template>
            </form>
        </c-col>
    </c-row>
</c-container>

<div class=" modal fade" id="result_modal" tabindex="-1" aria-labelledby="Result Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"> Result</h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="table_data_modal" aria-hidden="true">
                    <form cForm>
                        <ng-template [ngIf]="resultAPI">
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_name"> Status : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_name"> {{ result.success }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Request Id :</label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ result.requestId }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Random Id : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ result.randomId }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Random Seq Id : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ result.ramdomSeqId }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Upline Number : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ result.data.aboNumber }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Upline Name : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ result.data.aboName }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Upline BusinessNature : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status"> {{ result.data.aboBusinessNature }}</label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> TimeStamp : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status"> {{ result.data.timestamp }}</label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Accept Url : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status"> {{ result.data.uplineUrl }}</label>
                            </c-row>
                        </ng-template>
                        <ng-template [ngIf]="!resultAPI">
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_name"> Status : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_name"> {{ error.success }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Request Id :</label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ error.requestId }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Error Code : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ error.error.errorCode }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Error Msg : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ error.error.errorMsg }} </label>
                            </c-row>
                            <c-row>
                                <label [sm]="4" cCol cLabel="col" class="col-4 fw-bold text-end" for="add_role_status"> Error Debug : </label>
                                <label [sm]="8" cCol cLabel="col" for="add_role_status">{{ error.error.errorDebug }} </label>
                            </c-row>
                        </ng-template>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="clear()">
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdOpacity="0.1" bdColor="rgba(51,51,51,0.5)" size="large" color="#fff" type="ball-clip-rotate" [fullScreen]="true">
    <p class="text-white">Loading...</p>
</ngx-spinner>