/*
  {
    "id": 1,
    "name": "Admin",
    "status":"ACTIVE",
    "isDeleted": false,
    "createdDate": "2022-01-03T16:49:54.856904",
    "createdBy": "SYSTEM",
    "modifiedDate": "2022-01-03T16:49:54.856904",
    "modifiedBy": "SYSTEM"
  },

*/
export interface DemoRole{
    id?:number;
    name?:string;
    status?:string;
    isDeleted?:boolean;
    createdDate?:Date;
    createdBy?:string;
    modifiedDate?:Date;
    modifiedBy?:string;
}

export class DemoRoleStatus {
  static readonly STATUS_ACTIVE:string = "ACTIVE";
  static readonly STATUS_INACTIVE:string = "INACTIVE";

  constructor() {
      
  }

}