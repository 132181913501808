<c-container>
    <c-row>
        <c-col class="col-12 col-sm-9 col-md-5 mx-auto">
            <form cForm>
                <c-row class="mb-4">
                    <label class="col-4 text-end" cCol cLabel="col" for="search_period">
                        Period :
                    </label>
                    <c-col>
                        <input class="form-control" type="month" aria-label="search_period" data-date-format="yyyyMM"
                            id="search_period" name="search_period" [(ngModel)]="searchFormModel.period">
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                        Pool ABO :
                    </label>
                    <c-col>
                        <input aria-label="search_poolABO" type="text" name="search_poolABO" id="search_poolABO"
                            [(ngModel)]="searchFormModel.poolABO" cFormControl numbersOnly />
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <label class="col-4 text-end" cCol cLabel="col" for="search_random_Type">
                        Province :
                    </label>
                    <c-col>
                        <input aria-label="search_province" type="text" name="search_province" id="search_province"
                            [(ngModel)]="searchFormModel.province" maxlength="2" cFormControl />
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <label class="col-4 text-end" cCol cLabel="col" for="search_postcode">
                        Postcode :
                    </label>
                    <c-col>
                        <input aria-label="search_postcode" type="text" name="search_r_postcode" id="search_r_postcode"
                            [(ngModel)]="searchFormModel.postCode" maxlength="5" cFormControl numbersOnly />
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <label class="col-4 text-end" cCol cLabel for="inputEmail-1">Random Type :</label>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput id="RegistrationCheck" name="search_type_regis" type="checkbox"
                                [(ngModel)]="ramdomTypeRegis" (change)="onCheckboxTypeChange('registration', $event)"
                                checked />
                            <label cFormCheckLabel for="RegistrationCheck">Registration</label>
                        </c-form-check>
                    </c-col>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput id="CheckoutCheck" name="search_type_check_out" type="checkbox"
                                [(ngModel)]="ramdomTypeCheckout"
                                (change)="onCheckboxTypeChange('public_checkout', $event)" checked />
                            <label cFormCheckLabel for="CheckoutCheck">Check out</label>
                        </c-form-check>
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <c-col [offset]="4">
                        <c-row>
                            <c-col>
                                <button cButton color="primary" (click)="search()" class="w-100 text-white">
                                    Search
                                </button>
                            </c-col>
                            <c-col>
                                <button cButton color="light" class="w-100" (click)="clear()">
                                    Clear
                                </button>
                            </c-col>
                        </c-row>
                    </c-col>
                </c-row>
            </form>
        </c-col>
    </c-row>
</c-container>

<c-container>
    <c-row>
        <c-col>
            <span class="badge bg-info fs-5">{{ resultRecord }}</span>
            &nbsp;
            &nbsp;
            <span class="badge bg-warning fs-5">{{ resultText }}</span>
            <br>
            <br>
        </c-col>
    </c-row>
    <c-row>
        <c-col>
            <ag-grid-angular style="width: 100%; height: 50vh" class="ag-theme-alpine" [rowData]="searchRowData"
                [columnDefs]="searchColumnDefs" (gridReady)="onSearchGridReady($event)" [pagination]="true"
                [paginationPageSize]="paginationPageSize" [columnHoverHighlight]="false"
                [enableCellTextSelection]="true">
            </ag-grid-angular>
        </c-col>
    </c-row>
</c-container>

<div class="modal fade" id="history_modal" tabindex="-1" aria-labelledby="History Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="table_data_modal" aria-hidden="true">
                    <c-row>
                        <c-col class="col">
                            <c-row>
                                <label cLabel="col" class="col-2 fw-bold text-center"> Pool ABO </label>
                                <label cLabel="col" class="col"> {{ historyRandomModel.forAboNumber}}</label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-2 fw-bold text-center"> Province </label>
                                <label cLabel="col" class="col"> {{ historyRandomModel.province}}</label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-2 fw-bold text-center"> Postcode </label>
                                <label cLabel="col" class="col"> {{ historyRandomModel.postcode}}</label>
                            </c-row>
                        </c-col>
                    </c-row>
                    <c-row>
                        <c-col>
                            <ag-grid-angular style="width: 100%; height: 50vh" class="ag-theme-alpine"
                                [rowData]="searcHishRowData" [columnDefs]="searchishColumnDefs"
                                (gridReady)="onSearchHisGridReady($event)" [pagination]="true"
                                [paginationPageSize]="paginationPageSize" [columnHoverHighlight]="false"
                                [enableCellTextSelection]="true">
                            </ag-grid-angular>
                        </c-col>
                    </c-row>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal"> Close
                </button>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdOpacity="0.1" bdColor="rgba(51,51,51,0.5)" size="large" color="#fff" type="ball-clip-rotate"
    [fullScreen]="true">
    <p class="text-white">Loading...</p>
</ngx-spinner>