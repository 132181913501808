<c-row>
    <c-col >
      <c-card class="mb-3">
        <c-card-header>CampaignRole Create</c-card-header>
        <c-card-body>
          <form cForm>
            
            <c-row class="mb-3">
              <label [sm]="2" cCol cLabel="col" for="campaignrole_id">
                CampaignRole ID
              </label>
              <c-col [sm]="10">
                <input cFormControl name="campaignrole_id" id="campaignrole_id" type="text" [(ngModel)]="fModel.id" />
              </c-col>
            </c-row>
            <c-row class="mb-3">
              <label cLabel="col" class="col-sm-2" for="campaignrole_name">
                CampaignRole Name
              </label>
              <c-col [sm]="10">
                <input cFormControl name="campaignrole_name" id="campaignrole_name" type="text" [(ngModel)]="fModel.name" />
              </c-col>
            </c-row>
            <c-row class="mb-3">
              <label cLabel="col" class="col-sm-2" >
                
              </label>
              <c-col [sm]="10">
                <input (click)="save()" name="save_btn" cButton color="primary" type="button" value="Save" class="px-4" style="margin-right: 0.5rem;">
                <input [routerLink]="['/campaignrole']" name="back_btn" cButton color="secondary" type="button" value="Back" class="px-4">
              </c-col>
            </c-row>
  
            <!--
            <div class="mb-3">
            <label cLabel for="campaign_id">Campaign ID</label>
            <input cFormControl id="campaign_id" type="text" plaintext readonly value="{{model.id}}">
            
            <div cFormText>
            We'll never share your email with anyone else
            </div>
            
            </div>
            <div class="mb-3">
            <label cLabel for="inputPassword-1">Password</label>
            <input cFormControl id="inputPassword-1" type="password">
            </div>
            <c-form-check class="mb-3">
            <input cFormCheckInput id="checkMeOut-1" type="checkbox">
            <label cFormCheckLabel for="checkMeOut-1">Check me out</label>
            </c-form-check>
            <input cButton color="primary" type="submit">
            -->
          </form>
        </c-card-body>
      </c-card>
    </c-col>
  </c-row>