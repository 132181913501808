<c-row>
  <c-col lg="2" md="4">
    <c-widget-stat-e
      [title]="'title'"
      [value]="'1,123'"
      class="mb-4"
    >
      <c-chart [data]="data[0]" [options]="barOptions" class="mx-auto" height="40" width="80"></c-chart>
    </c-widget-stat-e>
  </c-col>
  <c-col lg="2" md="4">
    <c-widget-stat-e
      [title]="'title'"
      [value]="'1,123'"
      class="mb-4"
    >
      <c-chart [data]="data[1]" [options]="barOptions" class="mx-auto" height="40" width="80"></c-chart>
    </c-widget-stat-e>
  </c-col>
  <c-col lg="2" md="4">
    <c-widget-stat-e
      [title]="'title'"
      [value]="'1,123'"
      class="mb-4"
    >
      <c-chart [data]="data[2]" [options]="barOptions" class="mx-auto" height="40" width="80"></c-chart>
    </c-widget-stat-e>
  </c-col>
  <c-col lg="2" md="4">
    <c-widget-stat-e
      [title]="'title'"
      [value]="'1,123'"
      class="mb-4"
    >
      <c-chart [data]="data[3]" [options]="lineOptions" class="mx-auto" height="40" type="line" width="80"></c-chart>
    </c-widget-stat-e>
  </c-col>
  <c-col lg="2" md="4">
    <c-widget-stat-e
      [title]="'title'"
      [value]="'1,123'"
      class="mb-4"
    >
      <c-chart [data]="data[4]" [options]="lineOptions" class="mx-auto" height="40" type="line" width="80"></c-chart>
    </c-widget-stat-e>
  </c-col>
  <c-col lg="2" md="4">
    <c-widget-stat-e
      [title]="'title'"
      [value]="'1,123'"
      class="mb-4"
    >
      <c-chart [data]="data[5]" [options]="lineOptions" class="mx-auto" height="40" type="line" width="80"></c-chart>
    </c-widget-stat-e>
  </c-col>
</c-row>
