import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';
interface Configuration {
  uId?: number
  env?: string
  configGroup?: string
  configCode?: string
  configValue?: string
  configNameTh?: string
  configNameEn?: string
  configStatus?: string
  configEffectiveDateTime?: string
  configExpireDateTime?: string
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private http: HttpClient,
    private userService: UserService
    ) { } 
    private getHeader() {
      const token = this.userService.getAuthUser()?.access_token || '';
      const headers = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .set('Authorization', `Bearer ${token}`);
      return headers;
  }
  getConfigs(config: string, page: string, rowPerPage: string) {
    const headers = this.getHeader(); 
    const params = config == '' ? `page=${page}&rowPerPage=${rowPerPage}` : `config=${config}&page=${page}&rowPerPage=${rowPerPage}`
    return this.http.get(`${environment.api_url}/apibo/call/getwebconfigs?${params}`, { headers });
  }


  updateConfig(body : Configuration) {
    const headers = this.getHeader(); 
    const bodys = {...{updateBy:this.userService.getProfileUser()?.fullName},...body};
    return this.http.post(`${environment.api_url}/apibo/call/updatewebconfig`, bodys, { headers });
  }
}
