import { Component, OnDestroy } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

// https://www.ag-grid.com/javascript-data-grid/component-cell-renderer/
// https://blog.ag-grid.com/cell-renderers-in-ag-grid-every-different-flavour/
@Component({
  selector: 'history-btn-cell-renderer',
  template: `
    <button (click)="historyBtnClickedHandler($event)" class="btn btn-primary text-white w-100" >
      History
    </button>
  `,
})
export class HistoryBtnCellRenderer
  implements ICellRendererAngularComp, OnDestroy {
  private params: any;

  agInit(params: any): void {
    this.params = params;
  }

  historyBtnClickedHandler(event: any) {
    this.params.clicked(this.params);
  }
  /*getGui = function() {
      return this.eGui;
    };*/

  ngOnDestroy() {
    // no need to remove the button click handler
    // https://stackoverflow.com/questions/49083993/does-angular-automatically-remove-template-event-listeners
  }

  refresh(params: ICellRendererParams): boolean {
    return true;
  }
}
