import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { observable, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectOptionModel } from '../models/common/common.model';
import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root',
})
export class PoolDataService {
  constructor(private http: HttpClient) { }

  /** ########## RetryRandom Api ############ **/
  private formatErrors(error: any) {
    return throwError(error);
  }

  listPoolData(accessToken: string, params: any) {
    const headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${accessToken}`);

    let period = 'period=' + params.period.replace('-', '');
    let poolABO = '&aboId=' + params.poolABO
    let randomType = '&randomType=' + JSON.stringify(params.randomType);
    let stateCode = '&stateCode=' + params.province;
    let postCode = '&postCode=' + params.postCode;
    let page = '&page=1';
    let rowPerPage = '&rowPerPage=9999999';

    let parameter = '';
    parameter = parameter.concat(period)
    if (params.poolABO != '' && params.poolABO != undefined) {
      parameter = parameter.concat(poolABO)
    }
    if (params.randomType.length > 0) {
      parameter = parameter.concat(randomType)
    }
    if (params.province != '' && params.province != undefined) {
      parameter = parameter.concat(stateCode)
    }
    if (params.postCode != '' && params.postCode != undefined) {
      parameter = parameter.concat(postCode)
    }
    parameter = parameter.concat(page, rowPerPage)

    return this.http
      .get(`${environment.api_url}/apibo/call/pooldata-list?${parameter}`, { headers: headers })
      .pipe(catchError(this.formatErrors));
  }

}
