import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class AESEncryptDecrypt {
  secret_key = '3185d15c177648d980bf68a6c7179d1a'; // 32 byte // abopool_xn
  secret_iv = ''; // abopool_xi
  salt = '777ce50f1c8346e980deab678973511f'; // abopool_xs

  constructor() {}
  // aes 256 encrypt
  aesEncrypt(content: any) {
    const parsedkey = CryptoJS.enc.Utf8.parse(this.secret_key);
    const iv = CryptoJS.enc.Utf8.parse(this.secret_key);
    const encrypted = CryptoJS.AES.encrypt(content, parsedkey, {
      iv: iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString(CryptoJS.format.Hex);
  }

  aesDecrypt(word: any) {
    var keys = CryptoJS.enc.Utf8.parse(this.secret_key);
    let hex = CryptoJS.enc.Hex.parse(word);
    let src = CryptoJS.enc.Base64.stringify(hex);

    //let base64 = CryptoJS.enc.Base64.parse(word);
    //let src = CryptoJS.enc.Base64.stringify(base64);
    var decrypt = CryptoJS.AES.decrypt(src, keys, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return decrypt.toString(CryptoJS.enc.Utf8);
  }

  // hash256 data + salt
  hashGenerate(data: any) {
    let hash = CryptoJS.SHA256(data + this.salt).toString();
    return hash;
  }

  hashVerify(data: any, hash: any) {
    let hashVerify = CryptoJS.SHA256(data + this.salt).toString();
    return hashVerify == hash;
  }

  isTimestampValid(timestamp: any) {
    if (new Date().getTime() - timestamp.getTime() > 0) {
      return false;
    } else {
      return true;
    }
  }
}
