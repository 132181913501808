<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
    <c-container [fluid]="true">
        <c-row class="w-100">
            <c-col class="col-auto">
                <button toggle="visible" cHeaderToggler [cSidebarToggle]="sidebarId" class="ps-1">
                    <svg cIcon name="cilMenu" size="lg"></svg>
                </button>
            </c-col>
            <c-col class="col text-end my-auto">
                <span> Welcome : {{ fullName }}</span>
            </c-col>
            <c-col class="col-auto">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="logout()"> Logout </button>
            </c-col>
        </c-row>

    </c-container>

    <c-header-divider></c-header-divider>
    <c-container [fluid]="true">
        <!--
    <c-breadcrumb-router class="ms-2"></c-breadcrumb-router>
    -->
        <c-breadcrumb class="mb-2">
            <c-breadcrumb-item *ngFor="let item of headerNavigateModelList; index as i; last as isLast" [active]="isLast" [url]="item.url">
                {{ item.label }}
            </c-breadcrumb-item>
        </c-breadcrumb>
    </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
    <c-dropdown alignment="end" variant="nav-item">
        <button cButton color="" [caret]="false" cDropdownToggle class="py-0">
            <c-avatar cTextColor="primary" shape="rounded-1" size="md" src="./assets/img/avatars/8.jpg"
                status="success">
            </c-avatar>
        </button>
        <ul cDropdownMenu class="pt-0 pr-5 w-auto">
            <li>
                <h6 cDropdownHeader class="bg-light fw-semibold py-2">Account</h6>
            </li>
            <!--
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilBell"></svg>
          Updates
          <c-badge class="ms-2 float-end" color="info">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg>
          Messages
          <c-badge class="ms-2 float-end" color="success">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilTask"></svg>
          Tasks
          <c-badge class="ms-2 float-end" color="danger">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name='cilCommentSquare'></svg>
          Comments
          <c-badge class="ms-auto" color="warning">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <h6 cDropdownHeader class="bg-light fw-semibold py-2">Settings</h6>
      </li>
      <li></li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilUser"></svg>
          Profile
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilSettings"></svg>
          Settings
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilCreditCard"></svg>
          Payments
          <c-badge class="ms-2 float-end" color="secondary">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <a routerLink="./" cDropdownItem>
          <svg cIcon class="me-2" name="cilFile"></svg>
          Projects
          <c-badge class="ms-2 float-end" color="primary">
            42
          </c-badge>
        </a>
      </li>
      <li>
        <hr cDropdownDivider/>
      </li>
      -->
            <li>
                <a (click)="logout()" cDropdownItem>
                    <svg cIcon class="me-2" name="cilLockLocked"></svg>
                    Logout
                </a>
            </li>
            <li></li>
        </ul>
    </c-dropdown>
</ng-template>