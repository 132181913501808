import { Component, OnInit } from '@angular/core';
import { Campaign } from 'src/app/core/entity/campaign';
import { CampaignRole } from 'src/app/core/entity/campaignrole';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { AlertService } from 'src/app/core/services/alert.service';
//import { CampaignModel } from 'src/app/core/models/campaign/campaign.model';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { CampaignRoleService } from 'src/app/core/services/campaignrole.service';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-campaignrole',
  templateUrl: './campaignrole.component.html',
  styleUrls: ['./campaignrole.component.scss']
})
export class CampaignroleComponent implements OnInit {

  accessToken:string;
  campaignRoleList: Array<CampaignRole> = new Array();
/*
  colors = [
    { color: 'primary', textColor: 'primary' },
    { color: 'secondary', textColor: 'secondary' },
    { color: 'success', textColor: 'success' },
    { color: 'danger', textColor: 'danger' },
    { color: 'warning', textColor: 'warning' },
    { color: 'info', textColor: 'info' },
    { color: 'light' },
    { color: 'dark' }
  ];
*/
  constructor(
    private campaignRoleService:CampaignRoleService,
    private userService: UserService,
    private headerNavigateService: HeaderNavigateService,
    private alertService:AlertService
  ) { 
    this.accessToken = userService.getAuthUser().access_token||"";

  }

  ngOnInit(): void {
    this.loadCampaignRole();

    let headerNavigateModelList:Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'Campaign Role', url: '/campaignrole' }
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);
  }

  checkLogOut(error:any){
    if(error.status == 401){
      this.userService.logout();  
    }
  }

  private loadCampaignRole(){

    this.campaignRoleService.listCampaignRole(this.accessToken).subscribe({
      next:(v) => {
        this.campaignRoleList = v as Array<CampaignRole>;
      },
      error: (e) => {
        let errMsg = (e.message) ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';

        console.error(errMsg);
        this.checkLogOut(e);
        
      },
      complete: () => {
        //console.info('complete');
      } 
    })


  }

  delete(campaignRole:CampaignRole){
    if(confirm("Are you sure?")){
      this.campaignRoleService.deleteCampaignRole(this.accessToken,campaignRole.id).subscribe({
        next : ()=>{
          this.alertService.alertSuccess("Delete campaignRole ["+campaignRole.id+"] success");
          this.loadCampaignRole();
        },
        error:(e)=>
        {
          let errMsg = (e.message) ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';

          console.error(errMsg);
          this.alertService.alertError("Can't delete campaignRole ["+campaignRole.id+"], "+ errMsg);
        }
      });
    }else{

    }
  }


}
