import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';


import {
  AvatarModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  FormModule,
  GridModule,
  NavModule,
  ProgressModule,
  TableModule,
  TabsModule,
  UtilitiesModule
} from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { ChartjsModule } from '@coreui/angular-chartjs';

import { WidgetsModule } from '../../views/widgets/widgets.module';
import { AgGridModule } from 'ag-grid-angular';

import { CampaignroleComponent } from './campaignrole.component';
import { CampaignroleCreateComponent } from './campaignrole-create/campaignrole-create.component';
import { CampaignroleDetailComponent } from './campaignrole-detail/campaignrole-detail.component';



@NgModule({
  declarations: [
    CampaignroleComponent,
    CampaignroleCreateComponent,
    CampaignroleDetailComponent
  ],
  imports: [
    CommonModule,
    CardModule,
    NavModule,
    IconModule,
    TabsModule,
    GridModule,
    ProgressModule,
    ReactiveFormsModule,
    ButtonModule,
    FormsModule,
    FormModule,
    ButtonModule,
    ButtonGroupModule,
    ChartjsModule,
    AvatarModule,
    TableModule,
    WidgetsModule,
    UtilitiesModule,
    RouterModule,
    AgGridModule.withComponents([])
  ]
})
export class CampaignroleModule { }
