import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RetryRandomService } from 'src/app/core/services/retryrandom.service';
import { UserService } from 'src/app/core/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Random } from 'src/app/core/entity/historyrandom';
import { DatePipe } from '@angular/common';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';

interface RamdomFormModel {
  ramdomId: string | undefined;
  randomType: string | undefined;
  randomStatus: string | undefined;
  randomFor: string | undefined;
  orderNumber: string | undefined;
  paidStatus: string | undefined;
  province: string | undefined;
  postCode: string | undefined;
  flagCntCreditRandom: string | undefined;
  refRequest: string | undefined;
  acceptStatus: string | undefined;
  acceptUpline: string | undefined;
  randomSeqId: string | undefined;
  aboNumber: string | undefined;
  randomFrom: string | undefined;
  status: string | undefined;
  sendSMS: string | undefined;
  shortLink: string | undefined;
  emailRetryRandom: string | undefined;
  emailLastRandom: string | undefined;
}

@Component({
  selector: 'app-historyrandom',
  templateUrl: './historyrandom.component.html',
  styleUrls: ['./historyrandom.component.scss'],
})
export class HistoryrandomComponent implements OnInit {
  showtab1: boolean = false;
  showtab2: boolean = true;
  showtab3: boolean = true;
  randomId?: any;
  accessToken: string;
  pipe = new DatePipe('en-US');
  randomFormModelTab1: RamdomFormModel = {
    ramdomId: '',
    randomType: '',
    randomStatus: '',
    randomFor: '',
    orderNumber: '',
    paidStatus: '',
    province: '',
    postCode: '',
    flagCntCreditRandom: '',
    refRequest: '',
    acceptStatus: '',
    acceptUpline: '',
    randomSeqId: '',
    aboNumber: '',
    randomFrom: '',
    status: '',
    sendSMS: '',
    shortLink: '',
    emailRetryRandom: '',
    emailLastRandom: '',
  };

  randomFormModelTab2: RamdomFormModel = {
    ramdomId: '',
    randomType: '',
    randomStatus: '',
    randomFor: '',
    orderNumber: '',
    paidStatus: '',
    province: '',
    postCode: '',
    flagCntCreditRandom: '',
    refRequest: '',
    acceptStatus: '',
    acceptUpline: '',
    randomSeqId: '',
    aboNumber: '',
    randomFrom: '',
    status: '',
    sendSMS: '',
    shortLink: '',
    emailRetryRandom: '',
    emailLastRandom: '',
  };

  randomFormModelTab3: RamdomFormModel = {
    ramdomId: '',
    randomType: '',
    randomStatus: '',
    randomFor: '',
    orderNumber: '',
    paidStatus: '',
    province: '',
    postCode: '',
    flagCntCreditRandom: '',
    refRequest: '',
    acceptStatus: '',
    acceptUpline: '',
    randomSeqId: '',
    aboNumber: '',
    randomFrom: '',
    status: '',
    sendSMS: '',
    shortLink: '',
    emailRetryRandom: '',
    emailLastRandom: '',
  };

  constructor(
    private route: ActivatedRoute,
    private retryrandomService: RetryRandomService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private headerNavigateService: HeaderNavigateService
  ) {
    this.spinner.show();
    this.accessToken = userService.getAuthUser().access_token || '';
  }

  ngOnInit(): void {
    this.initialData();
    this.randomId = this.route.snapshot.queryParamMap.get('randomId');
    this.retryrandomService
      .getRandomById(this.accessToken, this.randomId)
      .subscribe({
        next: (v: any) => {
          if (v.data.length > 0) {
            let response = v.data as Array<Random>;
            console.log(v.data);
            if (response.length >= 0) {
              (this.randomFormModelTab1.ramdomId = response[0].randomId),
                (this.randomFormModelTab1.randomType = response[0].randomType),
                (this.randomFormModelTab1.randomStatus = response[0].randomStatus),
                (this.randomFormModelTab1.randomFor = response[0].aboNumber + ' ' + (response[0].aboBusinessNature == 'Member' ? 'M' : 'A') + ' ' + response[0].aboName);
              (this.randomFormModelTab1.orderNumber = response[0].order?.orderNumber + ' : ' + this.pipe.transform(response[0].order?.orderDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab1.paidStatus = response[0].order?.paidStatus + ' : ' + this.pipe.transform(response[0].order?.paidDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab1.province = response[0].province),
                (this.randomFormModelTab1.postCode = response[0].postcode),
                (this.randomFormModelTab1.flagCntCreditRandom = response[0].flagCntCreditRandom),
                (this.randomFormModelTab1.refRequest = response[0].refRequestId + ' : ' + this.pipe.transform(response[0].requestDatetime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab1.acceptStatus = response[0].accept?.acceptStatus + ' : ' + this.pipe.transform(response[0].accept?.acceptDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab1.acceptUpline = response[0].accept?.uplineNumber + '  ' + response[0].accept?.uplineName),
                (this.randomFormModelTab1.randomSeqId = response[0].random?.upline?.seqId),
                (this.randomFormModelTab1.aboNumber = response[0].aboNumber),
                (this.randomFormModelTab1.randomFrom = response[0].random?.upline?.randomFrom),
                (this.randomFormModelTab1.sendSMS = response[0].random?.upline?.sendSMSstatus + ' : ' + this.pipe.transform(response[0].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab1.status = response[0].random?.upline?.acceptStatus + ' : ' + this.pipe.transform(response[0].random?.upline?.acceptDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab1.shortLink = response[0].random?.upline?.acceptShortLinkUrl);
            }

            if (response.length > 1) {
              this.showtab2 = false;
              (this.randomFormModelTab2.randomSeqId = response[1].random?.upline?.seqId),
                (this.randomFormModelTab2.aboNumber = response[1].aboNumber),
                (this.randomFormModelTab2.randomFrom = response[1].random?.upline?.randomFrom),
                (this.randomFormModelTab2.sendSMS = response[1].random?.upline?.sendSMSstatus + ' : ' + this.pipe.transform(response[1].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab2.status = response[1].random?.upline?.acceptStatus + ' : ' + this.pipe.transform(response[1].random?.upline?.acceptDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab2.shortLink = response[1].random?.upline?.acceptShortLinkUrl);
            }

            if (response.length > 2) {
              this.showtab3 = false;
              (this.randomFormModelTab3.randomSeqId =
                response[2].random?.upline?.seqId),
                (this.randomFormModelTab3.aboNumber = response[2].aboNumber),
                (this.randomFormModelTab3.randomFrom =
                  response[2].random?.upline?.randomFrom),
                (this.randomFormModelTab3.sendSMS = response[2].random?.upline?.sendSMSstatus + ' : ' + this.pipe.transform(response[2].random?.upline?.sendSMSDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab3.status = response[2].random?.upline?.acceptStatus + ' : ' + this.pipe.transform(response[2].random?.upline?.acceptDateTime, 'dd/MM/yyyy HH:mm')),
                (this.randomFormModelTab3.shortLink = response[2].random?.upline?.acceptShortLinkUrl);

              this.randomFormModelTab3.emailRetryRandom = response[2].random?.upline?.sendMailManualstatus;
              this.randomFormModelTab3.emailLastRandom = response[2].random?.upline?.sendMailLastRandomstatus;
            }
            this.spinner.hide();
          }
        },
        error: (e) => {
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
          //console.info('complete');
        },
      });
  }
  initialData() {
    let headerNavigateModelList: Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'History Random', url: '/historyrandom' },
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);
  }
}
