import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { observable, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectOptionModel } from '../models/common/common.model';
import { Injectable } from '@angular/core';
import { DemoRole } from '../entity/demorole';

@Injectable({
    providedIn: 'root'
  })
export class DemoRoleService {
    constructor(
        private http: HttpClient
    ) {}


    /** ########## DemoRole Api ############ **/
    private formatErrors(error: any) {
        return throwError(error);
    }

    private getData():Array<DemoRole>{
      let demoRoleList:Array<DemoRole> = [
        {
          "id": 1,
          "name": "Admin",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": new Date("2022-01-03T16:49:54.856904"),
          "createdBy": "SYSTEM",
          "modifiedDate": new Date("2022-01-03T16:49:54.856904"),
          "modifiedBy": "SYSTEM"
        },
        {
          "id": 2,
          "name": "Fin",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": new Date("2022-01-03T16:49:54.856904"),
          "createdBy": "SYSTEM",
          "modifiedDate": new Date("2022-01-03T16:49:54.856904"),
          "modifiedBy": "SYSTEM"
        },
        {
          "id": 3,
          "name": "Isd",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": new Date("2022-01-03T16:49:54.856904"),
          "createdBy": "SYSTEM",
          "modifiedDate": new Date("2022-01-03T16:49:54.856904"),
          "modifiedBy": "SYSTEM"
        }
      ];

      return demoRoleList;
    }
    
    /** List Demo Role
     * GET /api/DemoRoles 
     * response Array<DemoRole>
     * [
        {
          "id": 1,
          "name": "Admin",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": 2,
          "name": "Fin",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": 3,
          "name": "Isd",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        }
      ]
    */
    listDemoRole(accessToken:string){
    
      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
      const body = {
        
      };
    
      return new Observable((observable) => {
        observable.next(this.getData());
      });
      /*
      return this.http
        .get(
          `${environment.api_url}/api/DemoRoles`,
          { headers }
        )
        .pipe(catchError(this.formatErrors));
        */
    }

    /** Search Demo Role
     * POST /api/SearchDemoRoles 
     * request
     * {
     *  "name":"Admin",
     *  "status":"ACTIVE"
     * }
     * response Array<DemoRole>
     * [
        {
          "id": 1,
          "name": "Admin",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": 2,
          "name": "Fin",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        },
        {
          "id": 3,
          "name": "Isd",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": "2022-01-03T16:49:54.856904",
          "createdBy": "SYSTEM",
          "modifiedDate": "2022-01-03T16:49:54.856904",
          "modifiedBy": "SYSTEM"
        }
      ]
    */
      searchDemoRole(accessToken:string, name:string, status:string){
    
        const headers = new HttpHeaders()
        .set("Content-Type","application/json")
        .set("Authorization", `Bearer ${accessToken}`)
        ;
        const body = {
          "name":name,
          "status":status
        };


      
        return new Observable((observable) => {
          observable.next(this.getData().filter(x => (name == ""?true:x.name == name) && (status == ""?true:x.status == status) ));
        });
        /*
        return this.http
          .post(
            `${environment.api_url}/api/SearchDemoRoles`,
            body,
            { headers }
          )
          .pipe(catchError(this.formatErrors));
          */
      }

    /**  Get Demo Role
     * GET /api/DemoRoles/{id} 
     * response DemoRole
     * {
        "id": 1,
        "name": "Admin",
        "isDeleted": false,
        "status":"ACTIVE",
        "createdDate": "2022-01-03T16:49:54.856904",
        "createdBy": "SYSTEM",
        "modifiedDate": "2022-01-03T16:49:54.856904",
        "modifiedBy": "SYSTEM"
      }


    */
    getDemoRole(accessToken:string, id:number | undefined){
    
      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
        const body = {
          
        };
      /*
        return this.http
          .get(
            `${environment.api_url}/api/DemoRoles/${id}`,
            { headers }
          )
          .pipe(catchError(this.formatErrors));
        */
          return new Observable((observable) => {
            let dataList = this.getData().filter(x => x.id == id);
            if(dataList.length > 0){
              observable.next(dataList[0]);
            }else{
              observable.next(null);
            }
            
          });
    }

    /** 
     * Add Demo Role
    POST /api/DemoRoles/
    request
    {
      "name": "Tester",
      "status":"ACTIVE"
    }

    response
    {
      "id": 1,
      "name": "Tester",
      "status":"ACTIVE",
      "isDeleted": false,
      "createdDate": "2022-01-03T17:40:09.5077262+07:00",
      "createdBy": "user_approver1",
      "modifiedDate": "2022-01-03T17:40:09.5077262+07:00",
      "modifiedBy": "user_approver1"
    }

 */
    addDemoRole(accessToken:string, name:string, status:string){
    
      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
        const body = {
            "name": name,
            "status":status
        };
      /*
        return this.http
          .post(
            `${environment.api_url}/api/DemoRoles`,
            body,
            { headers }
          )
          .pipe(catchError(this.formatErrors));
          */
        return new Observable((observable) =>{
          let returnData:DemoRole = {
            "id": 1,
            "name": "Tester",
            "status":"ACTIVE",
            "isDeleted": false,
            "createdDate":  new Date("2022-01-03T17:40:09.5077262+07:00"),
            "createdBy": "user_approver1",
            "modifiedDate": new Date("2022-01-03T17:40:09.5077262+07:00"),
            "modifiedBy": "user_approver1"
          };

          observable.next(returnData);

        });
    }

    /**
     * Update Demo Role
     * PUT /api/DemoRoles/{id}
     * *** ส่งเฉพาะ field ที่ต้องการ update ได้
     * request
     * {
        "name": "Tester Updated",
        "status":"ACTIVE"
      }

        response
        {
          "id": 1,
          "name": "Tester Updated",
          "status":"ACTIVE",
          "isDeleted": false,
          "createdDate": "2022-01-03T17:40:09.507726",
          "createdBy": "user_approver1",
          "modifiedDate": "2022-01-03T17:40:44.3879578+07:00",
          "modifiedBy": "user_approver1"
        }


     * 
     */
    updateDemoRole(accessToken:string, id:number | undefined,name:string| undefined, status:string|undefined){
    
      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
        const body = {
            "name": name
        };

        console.log(JSON.stringify(body));
      /*
        return this.http
          .put(
            `${environment.api_url}/api/DemoRoles/${id}`,
            body,
            { headers }
          )
          .pipe(catchError(this.formatErrors));
        */
        return new Observable((observable) =>{
          let returnData:DemoRole = {
            "id": 1,
            "name": "Tester",
            "status":"ACTIVE",
            "isDeleted": false,
            "createdDate":  new Date("2022-01-03T17:40:09.5077262+07:00"),
            "createdBy": "user_approver1",
            "modifiedDate": new Date("2022-01-03T17:40:09.5077262+07:00"),
            "modifiedBy": "user_approver1"
          };

          observable.next(returnData);

        });
    }
  
    /**
     * Delete Demo Role
     * DELETE /api/DemoRoles/{id}
        response
        {
        "id": 1,
        "name": "Tester Updated",
        "status":"ACTIVE";
        "isDeleted": true,
        "createdDate": "2022-01-03T17:40:09.507726",
        "createdBy": "user_approver1",
        "modifiedDate": "2022-01-03T17:41:17.3366481+07:00",
        "modifiedBy": "user_approver1"
      }

     */
    deleteDemoRole(accessToken:string, id:number | undefined){

      const headers = new HttpHeaders()
      .set("Content-Type","application/json")
      .set("Authorization", `Bearer ${accessToken}`)
      ;
        const body = {
           
        };
        /*
        return this.http
            .delete(
            `${environment.api_url}/api/DemoRoles/${id}`,
            
            { headers }
            )
            .pipe(catchError(this.formatErrors));
        */
            
        return new Observable((observable) =>{
          let returnData:DemoRole = {
            "id": 1,
            "name": "Tester",
            "status":"ACTIVE",
            "isDeleted": false,
            "createdDate":  new Date("2022-01-03T17:40:09.5077262+07:00"),
            "createdBy": "user_approver1",
            "modifiedDate": new Date("2022-01-03T17:40:09.5077262+07:00"),
            "modifiedBy": "user_approver1"
          };

          observable.next(returnData);

        });
    }
}