import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectOptionModel } from '../models/common/common.model';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
  })
export class LovService {



    constructor(
        private http: HttpClient
    ) {}

    
/*
    getFieldFunctionTypeOptionList(){
        // FieldFunctionType
        // ltrim, rtrim, trim, roundup, rounddown, roundup_2, rounddown_2
        let optionList:Array<SelectOptionModel> = new Array<SelectOptionModel>();
        {
            let option:SelectOptionModel  = {value:'',label:''};
            optionList.push(option);
        }
        {
            let option:SelectOptionModel  = {value:FieldFunctionType.type_ltrim,label:FieldFunctionType.type_ltrim};
            optionList.push(option);
        }
        {
            let option:SelectOptionModel  = {value:FieldFunctionType.type_rtrim,label:FieldFunctionType.type_rtrim};
            optionList.push(option);
        }
        {
            let option:SelectOptionModel  = {value:FieldFunctionType.type_trim,label:FieldFunctionType.type_trim};
            optionList.push(option);
        }
        {
            let option:SelectOptionModel  = {value:FieldFunctionType.type_roundup,label:FieldFunctionType.type_roundup};
            optionList.push(option);
        }
        {
            let option:SelectOptionModel  = {value:FieldFunctionType.type_rounddown,label:FieldFunctionType.type_rounddown};
            optionList.push(option);
        }
        {
            let option:SelectOptionModel  = {value:FieldFunctionType.type_roundup_2,label:FieldFunctionType.type_roundup_2};
            optionList.push(option);
        }
        {
            let option:SelectOptionModel  = {value:FieldFunctionType.type_rounddown_2,label:FieldFunctionType.type_rounddown_2};
            optionList.push(option);
        }

        //FieldDataType
        return optionList;
    }
    */

        /*
    getBonusDetail(token: string, period: string, params: URLSearchParams) {
        const headers = new HttpHeaders()
            .set('Authorization', `Bearer ${token}`);
        return this.http.get(`${environment.mashery_api_url}/rest/bonus/v3/account/detail/${period}?${params.toString()}`, { headers: headers }).pipe(catchError(this.formatErrors));
    }*/


    //getWorkflowStatusList(){

    //}
}

/*
export class DataSourceType {
    static readonly TYPE_NONE:string = "None";
    static readonly TYPE_RDBMS_MYSQL:string = "RDBMS_MySQL";
    static readonly TYPE_RDBMS_ORACLE:string = "RDBMS_Oracle";
    static readonly TYPE_RDBMS_SQLSERVER:string = "RDBMS_SQLServer";
    static readonly TYPE_RDBMS_ODBC:string = "RDBMS_ODBC";
    static readonly TYPE_HIVE:string = "Hive";
    static readonly TYPE_EXCEL:string = "Excel";
    static readonly TYPE_CSV:string = "CSV";
  
    constructor() {
        
    }
  
  }
  */