import { Component, LOCALE_ID, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { formatDate } from '@angular/common';

@Component({
    selector: 'datetime-cell',
    template: `<span>{{ formatTheDate() }}</span>`
})
export class DateTimeRenderer implements ICellRendererAngularComp {

    params: ICellRendererParams | undefined = undefined; 
    selectedDate: Date | undefined = undefined;

    /*constructor(params:ICellRendererParams, selectedDate: Date){
        this.params = params;
        this.selectedDate = selectedDate;
    }*/

    //constructor(@Inject(LOCALE_ID) public locale: string) { }

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.selectedDate = params.value;
    }

    formatTheDate() {
        //  Convert our selected Date into a readable format
        if (this.selectedDate == null || this.selectedDate == undefined)
            return "";

        return formatDate(this.selectedDate, 'dd-MM-yyyy HH:mm:ss.SSS ', "en-US");
    }

    
    refresh(params: ICellRendererParams): boolean {
        this.selectedDate = params.value;
        return true;
    }
}