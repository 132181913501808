import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule,FormsModule  } from '@angular/forms';
import { RouterModule } from '@angular/router';


import {
  AvatarModule,
  ButtonGroupModule,
  ButtonModule,
  CardModule,
  FormModule,
  GridModule,
  NavModule,
  ProgressModule,
  TableModule,
  TabsModule,
  UtilitiesModule
} from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { ChartjsModule } from '@coreui/angular-chartjs';

import { WidgetsModule } from '../../views/widgets/widgets.module';
import { AgGridModule } from 'ag-grid-angular';

import { DemoroleComponent } from './demorole.component';
import { EditBtnCellRenderer } from '../components/edit-button-cell-renderer.component';
import { DateTimeRenderer } from '../components/datetime-cell-renderer.component';



@NgModule({
  declarations: [
    DemoroleComponent
  ],
  imports: [
    CommonModule,
    CardModule,
    NavModule,
    IconModule,
    TabsModule,
    GridModule,
    ProgressModule,
    ReactiveFormsModule,
    ButtonModule,
    FormsModule,
    FormModule,
    ButtonModule,
    ButtonGroupModule,
    ChartjsModule,
    AvatarModule,
    TableModule,
    WidgetsModule,
    UtilitiesModule,
    RouterModule,
    AgGridModule.withComponents([EditBtnCellRenderer,DateTimeRenderer])
  ]
})
export class DemoroleModule { }
