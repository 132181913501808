import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CardModule, NavModule, TabsModule, GridModule, ProgressModule, ButtonModule, FormModule, ButtonGroupModule, AvatarModule, TableModule, UtilitiesModule } from '@coreui/angular';
import { ChartjsModule } from '@coreui/angular-chartjs';
import { IconModule } from '@coreui/icons-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CommonDirectiveModule } from 'src/app/core/directive/CommonDirective.module';
import { WidgetsModule } from 'src/app/views/widgets/widgets.module';
import { DateTimeRenderer } from '../components/datetime-cell-renderer.component';
import { ApilogComponent } from './apilog.component';
import { DetailBtnCellRenderer } from '../components/detail-button-cell-renderer.component';



@NgModule({
  declarations: [ApilogComponent],
  imports: [
    CommonModule,
    CardModule,
    NavModule,
    IconModule,
    TabsModule,
    GridModule,
    ProgressModule,
    ReactiveFormsModule,
    ButtonModule,
    FormsModule,
    FormModule,
    ButtonModule,
    ButtonGroupModule,
    ChartjsModule,
    AvatarModule,
    TableModule,
    WidgetsModule,
    UtilitiesModule,
    RouterModule,
    NgbModule,
    NgxSpinnerModule,
    CommonDirectiveModule,
    AgGridModule.withComponents([DetailBtnCellRenderer, DateTimeRenderer]),
  ]
})
export class ApilogModule { }
