export class Util{
    
    static isNullOrEmpty(value:any){
        if(value == undefined || value == "" || value == null){
            return true;
        }else{
            return false;
        }
    }

    static isNull(value:any){
        if(value == undefined || value == null){
            return true;
        }else{
            return false;
        }
    }
}