import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { RetryRandomService } from 'src/app/core/services/retryrandom.service';
import { UserService } from 'src/app/core/services/user.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { error, subError } from 'src/app/core/entity/error';
import { SelectOptionModel } from 'src/app/core/models/common/common.model';
import { Util } from 'src/app/core/util/util';


declare var $: any;

interface RamdomFormModel {
  period: string | undefined;
  province: string | undefined;
  postCode: string | undefined;
  requestDistNumber: string | undefined;
  businessNature: string | undefined;
  orderNumber: string | undefined;
  orderValue: string;
  orderDateTime: string | undefined;
  orderPv: string | undefined;
  orderBv: string | undefined;
  randomId: string | undefined;
}
interface dataFromAPI {
  aboBusinessNature: string | undefined;
  aboName: string | undefined;
  aboNumber: string | undefined;
  timestamp: string | undefined;
  uplineUrl: string | undefined;
}
interface ResultFromAPI {
  randomId: string | undefined;
  ramdomSeqId: string | undefined;
  requestId: string | undefined;
  success: string | undefined;
  data: dataFromAPI;
}


@Component({
  selector: 'app-newrandom',
  templateUrl: './newrandom.component.html',
  styleUrls: ['./newrandom.component.scss'],
})
export class NewRandomComponent implements OnInit {
  modelDate: NgbDateStruct | null = null;
  date: { year: number; month: number } | null = null;
  TemRegis: boolean = false;
  TemCheckout: boolean = false;
  TemConfirmpaid: boolean = false;
  accessToken: string;
  resultAPI: boolean = false;

  businessNatureOptionList: Array<SelectOptionModel> = [
    {
      value: 'BusinessOwner',
      label: 'Business Owner',
    },
    {
      value: 'Member',
      label: 'Member',
    },
    {
      value: 'GuestCustomer',
      label: 'Guest Customer',
    },
  ];

  model: RamdomFormModel = {
    period: '',
    province: '',
    postCode: '',
    requestDistNumber: '',
    businessNature: '',
    orderNumber: '',
    orderValue: '',
    orderDateTime: '',
    orderPv: '',
    orderBv: '',
    randomId: '',
  };

  data: dataFromAPI = {
    aboBusinessNature: '',
    aboName: '',
    aboNumber: '',
    timestamp: '',
    uplineUrl: '',
  }

  result: ResultFromAPI = {
    randomId: '',
    ramdomSeqId: '',
    requestId: '',
    success: '',
    data: this.data,
  }

  subError: subError = {
    errorCode: '',
    errorDebug: '',
    errorMsg: '',
  }
  error: error = {
    error: this.subError,
    requestId: '',
    success: '',
  }

  constructor(
    private headerNavigateService: HeaderNavigateService,
    private retryrandomService: RetryRandomService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private cd: ChangeDetectorRef
  ) {
    this.accessToken = userService.getAuthUser().access_token || '';
  }
  onChange(event: any) {
    this.model.postCode = event;
    this.cd.detectChanges();
  }

  ngOnInit(): void {
    this.initialData();
  }

  initialData() {
    let headerNavigateModelList: Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'New Random', url: '/newrandom' },
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);
  }

  random() {
    this.resultAPI = false;
    this.spinner.show();
    this.alertService.clearAlert();
    if (this.validate()) {

      let randomType = this.TemRegis ? 'registration' : this.TemCheckout ? 'public_checkout' : '';
      this.retryrandomService.newRandom(this.accessToken, this.model, randomType).subscribe({
        next: (res) => {
          this.resultAPI = true;
          this.result = res as ResultFromAPI;
          $('#result_modal').modal('show');
          this.spinner.hide();
        },
        error: (e: any) => {
          this.error = e.error as error;
          this.resultAPI = false;
          $('#result_modal').modal('show');
          this.spinner.hide();
        },
        complete: () => {
          this.spinner.hide();
        },
      });;
    } else {
      this.spinner.hide();
    }
  }

  validate() {

    this.model.period = this.model.period?.replace('-', '');
    if (this.TemRegis) {
      if (this.model.period == '' || this.model.period == undefined) {
        this.alertService.alertError('Please Input Period');
        return false;
      }
      // else if (this.model.period.length < 6) {
      //   this.alertService.alertError('Period is incorrct FORMAT');
      //   return false;
      // }
      else if (this.model.province == '' || this.model.province == undefined) {
        this.alertService.alertError('Please Input Province');
        return false;
      } else if (this.model.postCode == '' || this.model.postCode == undefined) {
        this.alertService.alertError('Please Input Postcode');
        return false;
      }
      return true;
    } else if (this.TemCheckout) {
      //if (this.model.period == '' || this.model.period == undefined) {
      //  this.alertService.alertError('Please Input Period');
      //  return false;
      //}
      // else if (this.model.period.length < 6) {
      //   this.alertService.alertError('Period is incorrct FORMAT');
      //   return false;
      // } 
      if (this.model.province == '' || this.model.province == undefined) {
        this.alertService.alertError('Please Input Province');
        return false;
      } else if (this.model.postCode == '' || this.model.postCode == undefined) {
        this.alertService.alertError('Please Input Postcode');
        return false;
      } else if(!Util.isNullOrEmpty(this.model.requestDistNumber) && this.model.requestDistNumber!.length < 4){
        this.alertService.alertError('Please enter Request Dist Number at least 4 digits.');
        return false;
      } else if (this.model.orderPv == '' || this.model.orderPv == undefined) {
        this.alertService.alertError('Please Input PV');
        return false;
      } else if (this.model.orderBv == '' || this.model.orderBv == undefined) {
        this.alertService.alertError('Please Input BV');
        return false;
      }
      
      /* else if (this.model.requestDistNumber == '' || this.model.requestDistNumber == undefined) {
        this.alertService.alertError('Please Input Request Dist Number');
        return false;
      } else if (this.model.requestDistNumber.length < 4) {
        this.alertService.alertError('Please enter Request Dist Number at least 4 digits.');
        return false;
      } else if (this.model.businessNature == '' || this.model.businessNature == undefined) {
        this.alertService.alertError('Please Input Business Nature');
        return false;
      } else if (this.model.orderNumber == '' || this.model.orderNumber == undefined) {
        this.alertService.alertError('Please Input Order No.');
        return false;
      } else if (this.model.orderValue == '' || this.model.orderValue == undefined) {
        this.alertService.alertError('Please Input Order Value');
        return false;
      } else if (this.model.orderDateTime == '' || this.model.orderDateTime == undefined) {
        this.alertService.alertError('Please Input Order Date Time');
        return false;
      } else if (this.model.orderPv == '' || this.model.orderPv == undefined) {
        this.alertService.alertError('Please Input PV');
        return false;
      } else if (this.model.orderBv == '' || this.model.orderBv == undefined) {
        this.alertService.alertError('Please Input BV');
        return false;
      }*/
      return true;
    } else if (this.TemConfirmpaid) {
      if (this.model.randomId == '' || this.model.randomId == undefined) {
        this.alertService.alertError('Please Input Random Id');
        return false;
      } else if (this.model.period == '' || this.model.period == undefined) {
        this.alertService.alertError('Please Input Period');
        return false;
      } else if (this.model.requestDistNumber == '' || this.model.requestDistNumber == undefined) {
        this.alertService.alertError('Please Input Request Dist Number');
        return false;
      } else if (this.model.requestDistNumber.length < 4) {
        this.alertService.alertError('Please enter Request Dist Number at least 4 digits.');
        return false;
      } else if (this.model.businessNature == '' || this.model.businessNature == undefined) {
        this.alertService.alertError('Please Input Business Nature');
        return false;
      } else if (this.model.orderNumber == '' || this.model.orderNumber == undefined) {
        this.alertService.alertError('Please Input Order No.');
        return false;
      } else if (this.model.orderValue == '' || this.model.orderValue == undefined) {
        this.alertService.alertError('Please Input Order Value');
        return false;
      } else if (this.model.orderDateTime == '' || this.model.orderDateTime == undefined) {
        this.alertService.alertError('Please Input Order Date Time');
        return false;
      } else if (this.model.orderPv == '' || this.model.orderPv == undefined) {
        this.alertService.alertError('Please Input PV');
        return false;
      } else if (this.model.orderBv == '' || this.model.orderBv == undefined) {
        this.alertService.alertError('Please Input BV');
        return false;
      }
      return true;
    }
    return true;
  }

  show(param: any) {
    this.TemRegis = false;
    this.TemCheckout = false;
    this.TemConfirmpaid = false;
    if (param == 'TemRegis') {
      this.TemRegis = true;
    } else if (param == 'TemCheckout') {
      this.TemCheckout = true;
    } else {
      this.TemConfirmpaid = true;
    }
    this.clear()
  }

  clear() {
    this.model.period = '',
      this.model.province = '',
      this.model.postCode = '',
      this.model.requestDistNumber = '',
      this.model.businessNature = '',
      this.model.orderNumber = '',
      this.model.orderValue = '',
      this.model.orderDateTime = '',
      this.model.orderPv = '',
      this.model.orderBv = '',
      this.model.randomId = ''
  }

  checkDigit() {
    let amount = this.model.orderValue;
    this.model.orderValue = parseFloat(amount).toFixed(2);
  }
}
