<c-row>
    <c-col>
        <c-row class="mb-3">
            <label cCol cLabel="col" class="col-2" for="add_role_name"> Random ID </label>
            <label cCol cLabel="col" class="col-10" for="add_role_name">
                {{ randomFormModelTab1.ramdomId }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Random type
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.randomType }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Random Status
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.randomStatus }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Random for
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.randomFor }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Order No.
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.orderNumber }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Paid Status
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.paidStatus }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Province
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.province }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Postcode
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.postCode }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Cnt. Creadit
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.flagCntCreditRandom }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Ref. Request
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.refRequest }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Accept Status
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.acceptStatus }}
            </label>
        </c-row>
        <c-row class="mb-3">
            <label cLabel="col" class="col-2" for="add_role_status">
                Accept Upline
            </label>
            <label cLabel="col" class="col-10" for="add_role_status">
                {{ randomFormModelTab1.acceptUpline }}
            </label>
        </c-row>
    </c-col>
</c-row>
<c-nav variant="tabs">
    <a [active]="true" [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="0" cNavLink [disabled]="showtab1">1st</a>
    <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="1" cNavLink [disabled]="showtab2">2nd </a>
    <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="2" cNavLink [disabled]="showtab3">3rd</a>
</c-nav>
<c-tab-content #tabContent="cTabContent">
    <c-tab-pane class="p-3">
        <c-row>
            <c-col>
                <c-card class="mb-3">
                    <c-card-header style="">
                        <div style="position: relative; display: inline-block"></div>
                        <div style="position: relative; display: inline-block; float: right"></div>
                    </c-card-header>
                    <c-card-body>
                        <form cForm>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    RandomSeqId
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab1.randomSeqId }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    ABO
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab1.aboNumber }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Random From
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab1.randomFrom }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Link Accpet
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab1.sendSMS }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    SMS ShortLink
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab1.shortLink }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Accept Status
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab1.status }}
                                </label>
                            </c-row>
                            <!-- <c-row class="mb-3">
                <label cLabel="col" class="col-2" for="add_role_status">
                  Send SMS
                </label>
                <label cLabel="col" class="col-10" for="add_role_status">
                  {{ randomFormModelTab1.sendSMS }}
                </label>
              </c-row> -->
                        </form>
                    </c-card-body>
                </c-card>
            </c-col>
        </c-row>
    </c-tab-pane>
    <c-tab-pane class="p-3">
        <c-row>
            <c-col>
                <c-card class="mb-3">
                    <c-card-header style="">
                        <div style="position: relative; display: inline-block"></div>
                        <div style="position: relative; display: inline-block; float: right"></div>
                    </c-card-header>
                    <c-card-body>
                        <form cForm>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    RandomSeqId
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab2.randomSeqId }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    ABO
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab2.aboNumber }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Random From
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab2.randomFrom }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Link Accpet
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab2.sendSMS }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    SMS ShortLink
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab2.shortLink }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Accept Status
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab2.status }}
                                </label>
                            </c-row>
                        </form>
                    </c-card-body>
                </c-card>
            </c-col>
        </c-row>
    </c-tab-pane>
    <c-tab-pane class="p-3">
        <c-row>
            <c-col>
                <c-card class="mb-3">
                    <c-card-header style="">
                        <div style="position: relative; display: inline-block"></div>
                        <div style="position: relative; display: inline-block; float: right"></div>
                    </c-card-header>
                    <c-card-body>
                        <form cForm>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    RandomSeqId
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab3.randomSeqId }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    ABO
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab3.aboNumber }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Random From
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab3.randomFrom }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Link Accpet
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab3.sendSMS }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    SMS ShortLink
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab3.shortLink }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Accept Status
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab3.status }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Email Retry Random
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab3.emailRetryRandom }}
                                </label>
                            </c-row>
                            <c-row class="mb-3">
                                <label cLabel="col" class="col-2" for="add_role_status">
                                    Email Last Ramdom
                                </label>
                                <label cLabel="col" class="col-10" for="add_role_status">
                                    {{ randomFormModelTab3.emailLastRandom }}
                                </label>
                            </c-row>
                        </form>
                    </c-card-body>
                </c-card>
            </c-col>
        </c-row>
    </c-tab-pane>
</c-tab-content>
<ngx-spinner bdOpacity="0.1" bdColor="rgba(51,51,51,0.5)" size="large" color="#fff" type="ball-clip-rotate"
    [fullScreen]="true">
    <p style="color: white">Loading...</p>
</ngx-spinner>