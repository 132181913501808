import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { switchMap } from 'rxjs';

import { SelectOptionModel } from 'src/app/core/models/common/common.model';

import { AlertService } from 'src/app/core/services/alert.service';
import { LovService } from 'src/app/core/services/lov.service';
import { UserService } from 'src/app/core/services/user.service';

import { deepCopy } from '@angular-devkit/core/src/utils/object';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { CampaignRole } from 'src/app/core/entity/campaignrole';
import { CampaignRoleService } from 'src/app/core/services/campaignrole.service';
/*
  const newObject = deepCopy(this.object);
  const newArray = deepCopy(this.array);
*/

declare var $:any;

interface FormModel{
  name:string
}


@Component({
  selector: 'app-campaignrole-detail',
  templateUrl: './campaignrole-detail.component.html',
  styleUrls: ['./campaignrole-detail.component.scss']
})
export class CampaignroleDetailComponent implements OnInit {

  
  //workflowStatusList:Array<WorkflowStatus> = new Array<WorkflowStatus>();
  //workflowStatusOptionList:Array<SelectOptionModel> = new Array<SelectOptionModel>();
  //campaignList: Array<CampaignModel> = new Array();
  campaignRole?:CampaignRole;
  //tablePreview?:TablePreview;

  fModel:FormModel = {
    name:""
  }

  accessToken:string;

  id:string | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private campaignRoleService: CampaignRoleService,
    private alertService: AlertService,
    private lovService : LovService,
    private userService: UserService,
    private headerNavigateService: HeaderNavigateService
  ) { 
    this.accessToken = userService.getAuthUser().access_token||"";
  }

  ngOnInit(): void {
/*
    this.route.queryParams.subscribe((params) => {
      
      this.id = params["id"];

      if(!this.id || this.id == ""){
        this.router.navigate(["/"]);
      }
    });
*/

    this.route.paramMap.subscribe((params : ParamMap)=> { 
      let idParam = params.get('id');  
      if(!idParam || idParam == ""){
        this.router.navigate(["/"]);
        return;
      }else{
        this.id = (idParam);
        setTimeout(()=>{
          this.loadInitialData();
        },1000);
      }
    });

    

    
  }

  loadInitialData(){

    let headerNavigateModelList:Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'Campaign Role', url: '/campaignrole' },
      { label: this.id, url: '/campaignrole/'+this.id },      
      
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);
    
    //this.loadCampaign();

   this.loadCampaignRole();

    
  }

  ngAfterViewInit(){
    //this.initialModalScript();
  }

  
  checkLogOut(error:any){
    if(error.status == 401){
      this.userService.logout();  
    }
  }

  loadCampaignRole(){
    this.campaignRoleService.getCampaignRole(this.accessToken, this.id).subscribe({
      next: (v) => {
        console.log("v = " + v);
        this.campaignRole = v as CampaignRole;    
        this.fModel.name = this.campaignRole?.name || "";
        //this.loadTableField(true);
        //this.fModel.workflowStatusId = this.shareDataSource?.workflowStatusId?.toString() || "";
      },
      error: (e) => {
        let errMsg = (e.message) ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';

        console.error(errMsg);
        this.alertService.alertError("Not Found");
        this.checkLogOut(e);
        //this.router.navigate(['/campaign']);

      },
      complete: () => {
        //console.info('complete');
      } 
    });
  }


  save(){
    

    this.campaignRoleService.updateCampaignRole(this.accessToken, this.id,
      this.fModel.name).subscribe({
        next: (v:any) => {
          this.campaignRole = v as CampaignRole;    
          console.log("update campaignRole = " + JSON.stringify(this.campaignRole));
          this.fModel.name = this.campaignRole?.name || "";
          
          //this.loadTableField(false);
          this.alertService.alertSuccess("Successful : update campaignRole ("+this.campaignRole.id+")");
        },
        error: (e:any) => {
          let errMsg = (e.message) ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';
  
          console.error(errMsg);
          this.alertService.alertError("Exception : can't update ["+errMsg+"]");
          //this.router.navigate(['/campaign']);
  
        },
        complete: () => {
          //console.info('complete');
        } 
      });

  }

}
