import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from './user.service';
import { environment } from 'src/environments/environment';

export interface Params {
  site: string;
}


@Injectable({
    providedIn: 'root'
  })
export class AuthGuard implements CanActivate {

  //site;
  params:any;

  constructor(
    private router: Router,
    private userService: UserService
  ) {
    
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    this.params = route.queryParams;
    {
      if(!this.userService.isLoggedIn()) {
        //localStorage.setItem('redirect_uri', state.url.split('?')[0]);
        this.router.navigateByUrl("/login");
        //location.href = environment.hybris_login_url;
        // this.router.navigateByUrl('/receiveauth?user_context=31679772&return_type=redirect&access_token=6qj9p7xdupkxnpbsvhk67h9w&token_type=bearer&expires_in=5&refresh_token=&scope=aboNum%3D4002344 salesPlanAff%3D200 partyId%3D31679772&state=&uri=&extended=');
        return false;
      } else {
        /*if(localStorage.getItem('redirect_uri')) {
          let redirect_uri = localStorage.getItem('redirect_uri');
          localStorage.removeItem('redirect_uri');
          this.router.navigate([redirect_uri]);
        }*/
        return true;
      }
    }

  }

}
