import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CampaignRole } from 'src/app/core/entity/campaignrole';
import { SelectOptionModel } from 'src/app/core/models/common/common.model';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { AlertService } from 'src/app/core/services/alert.service';
import { CampaignService } from 'src/app/core/services/campaign.service';
import { CampaignRoleService } from 'src/app/core/services/campaignrole.service';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { LovService } from 'src/app/core/services/lov.service';
import { UserService } from 'src/app/core/services/user.service';

interface FormModel{
  id:string,
  name:string
}


@Component({
  selector: 'app-campaignrole-create',
  templateUrl: './campaignrole-create.component.html',
  styleUrls: ['./campaignrole-create.component.scss']
})
export class CampaignroleCreateComponent implements OnInit {

  
  fModel:FormModel = {
    id:"",
    name:""
  }

  accessToken:string;

  

  constructor(
    private lovService : LovService,
    private alertService : AlertService,
    private campaignRoleService : CampaignRoleService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private headerNavigateService: HeaderNavigateService
    ) { 
      this.accessToken = userService.getAuthUser().access_token||"";
/*
      workflowStatusService.listWorkflowStatus().subscribe({
        next: (v) => {
          console.log("v = " + v);
          this.workflowStatusList = v as Array<WorkflowStatus>;    
        },
        error: (e) => {
          let errMsg = (e.message) ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';
  
          console.error(errMsg);
          
        },
        complete: () => {
          //console.info('complete');
        } 
    });
*/
  }

    

  ngOnInit(): void {

    let headerNavigateModelList:Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'Campaign Role', url: '/campaignrole' },
      { label: 'Create', url: '/campaignrole-create'},
      
      
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);

  }


  save(){
    // validate
    if(this.fModel.id.trim() == ""){
      this.alertService.alertError("require [id]");
      return;
    }
    
    if(this.fModel.name.trim() == ""){
      this.alertService.alertError("require [name]");
      return;
    }


    this.campaignRoleService.addCampaignRole(this.accessToken, this.fModel.id, this.fModel.name
      ).subscribe({
      next : (v:any)=>{
        let campaignRole = v as CampaignRole;
        console.log("createCampaignRole =  "+JSON.stringify(campaignRole));
        this.router.navigate(['/campaignrole', campaignRole.id]);
      },
      error: (e:any) => {
        let errMsg = (e.message) ? e.message : e.status ? `${e.status} - ${e.statusText}` : 'Server error';

        console.error(errMsg);
        
        this.checkLogOut(e);
      },
      complete: () => {
        //console.info('complete');
      } 
    });
    
    

  }

  checkLogOut(error:any){
    if(error.status == 401){
      this.userService.logout();  
    }
  }


}
