import { Component } from '@angular/core';
import { UserService } from 'src/app/core/services/user.service';

import { navItems } from './_nav';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
})
export class DefaultLayoutComponent {

  nav: any = [];
  permission = [];

  public perfectScrollbarConfig = {
    suppressScrollX: true,
  };

  constructor(private userService: UserService,) {
    this.permission = userService.getPermission().map((x: any) => {
      return x.permcode.replace('menu.', '')
    });

  }

  ngOnInit(): void {
    let arr: any = navItems;
    this.permission.forEach((item) => {
      var menu = arr.findIndex((x: any) => x.name?.toLocaleLowerCase() == item);
      if (menu >= 0) {
        this.nav.push(arr[menu]);
      }
    })
  }
}