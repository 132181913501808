import { Component, OnInit } from '@angular/core';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(private headerNavigateService: HeaderNavigateService) {}

  ngOnInit(): void {
    let headerNavigateModelList: Array<HeaderNavigateModel> = [
      { label: 'Home', url: '/', attributes: { title: 'Home' } },
      { label: 'Dashboard', url: '/dashboard' },
    ];
    this.headerNavigateService.setNavigate(headerNavigateModelList);
  }
}
