import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
})
export class DefaultHeaderComponent extends HeaderComponent {
  @Input() sidebarId: string = 'sidebar';

  public newMessages = new Array(4);
  public newTasks = new Array(5);
  public newNotifications = new Array(5);

  headerNavigateModelList: Array<HeaderNavigateModel> =
    new Array<HeaderNavigateModel>();
  fullName: string;
  constructor(
    private classToggler: ClassToggleService,
    private userService: UserService,
    private headerNavigateService: HeaderNavigateService
  ) {
    super();

    this.fullName = userService.getProfileUser().fullName || '';
  }

  ngOnInit(): void {
    // this.commonService.setIsActivePrintPdf(this.isActivePrintPdf);
    /*
    this.downloadCsvSubscription =
      this.commonService.downloadCsvEvent.subscribe(
        (report: string) => {
          if ("los" === report) {
            this.downloadCsv();
            //this.downloadClassicVersionEvent.unsubscribe();
          }
        }
      );
    */
    this.headerNavigateService.setNavigateEvent.subscribe(
      (headerNavigateModelListString: string) => {
        this.headerNavigateModelList = JSON.parse(
          headerNavigateModelListString
        );
      }
    );
  }

  logout() {
    this.userService.logout();
  }
}
