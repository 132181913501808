import { Component, OnInit } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
// import { MessageService } from 'primeng/api';
import { ConfigurationService } from 'src/app/core/services/configuration.service';
import { UserService } from 'src/app/core/services/user.service';
import { ConfigActionButton } from './components/config.actionbtn/config.actionbtn.component';
declare const bootstrap: any;
import * as moment from 'moment';
import { HeaderNavigateService } from 'src/app/core/services/headernavigate.service';
import { HeaderNavigateModel } from 'src/app/core/models/common/headernavigate.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { AlertService } from 'src/app/core/services/alert.service';

interface Configuration {
    uId?: number
    env?: string
    configGroup?: string
    configCode?: string
    configValue?: string
    configNameTh?: string
    configNameEn?: string
    configStatus?: string
    configEffectiveDateTime?: string
    configExpireDateTime?: string
}

interface SearchOption {
    configCode: string;
}

interface PageInfo {
    page: number;
    rowPerPage: number;
    totalRecord: number;
    allpage: number;
}

@Component({
    selector: 'app-config',
    templateUrl: './config.component.html',
    styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {

    role: string = '';
    userName: string = '';
    configurationList: Configuration[] = [];
    configuration: Configuration = {}
    pageNumber: number = 1;
    asingRoleConditionModal: boolean = false;
    isValidAbo: boolean = false;

    configStatus: string[] = ["ACTIVE", "INACTIVE"];
    selectedStatus: string = "";
    configEffectiveDatetime: Date| null  = moment().startOf('day').toDate();
    configExpireDatetime: Date| null  = moment().startOf('day').toDate()

    actionBtnOptions: any = {
        editConfig: false,
    };

    ruleColumnDefs: ColDef[] = [];


    private gridApi!: GridApi;

    searchOptions: SearchOption = {
        configCode: '',
    };

    pageInfo: PageInfo = {
        page: 1,
        rowPerPage: 9999999,
        totalRecord: 0,
        allpage: 0,
    };

    permCode: string = "menu.config";
    defaultDateEffective = moment({hour: 0, minute: 0, second: 1}).toDate()
    defaultDateExpire = moment({hour: 23, minute: 59, second: 59}).toDate()

    constructor(
        // private messageService: MessageService,
        private headerNavigateService: HeaderNavigateService,
        private userService: UserService,
        private configurationService: ConfigurationService,
        private spinner: NgxSpinnerService,
        private alertService: AlertService,
    ) { }

    ngOnInit() {
        this.getPermission();
        this.initialColDef();
        this.initialHeader();
    }
    initialHeader() {
        let headerNavigateModelList: Array<HeaderNavigateModel> = [
          { label: 'Home', url: '/', attributes: { title: 'Home' } },
          { label: 'Config', url: '/config' },
        ];
        this.headerNavigateService.setNavigate(headerNavigateModelList);
      }

    ngAfterViewInit(): void {
        this.getWebConfigList('');
    }

    ngOnDestroy() {
      this.alertService.clearAlert();
    }

    getPermission() {
        const userInfo = this.userService.getProfileUser() as any;
        const userPerm = this.userService.getPermission() as any;

        this.role = userInfo.role;
        this.userName = userInfo.fullName;

        //check config permission canUpdate
        const configPerm = userPerm.find((perm: any) => perm.permcode == this.permCode);
        if(configPerm && configPerm.canUpdate){
            this.actionBtnOptions.editConfig = true;
        }
    }

    frameworkComponents = {
        actionButton: ConfigActionButton,
    }

    initialColDef() {
        this.ruleColumnDefs = [
            {
                headerName: 'Action',
                cellRenderer: 'actionButton',
                cellRendererParams: {
                    onClickEditConfig: (data: any) => {
                        this.onClickEditConfig(data);
                    },
                    actionBtnOptions: this.actionBtnOptions
                },
                width: 180,
            },
            {
                headerName: "uId",
                field: 'uId',
                sortable: true,
                width: 100
            },
            {
                headerName: "env",
                field: 'env',
                sortable: true,
                width: 100
            },
            {
                headerName: 'configGroup',
                field: 'configGroup',
                sortable: true,
                width: 160
            },
            {
                headerName: 'configCode',
                field: 'configCode',
                sortable: true,
                width: 250
            },
            {
                headerName: 'configValue',
                field: 'configValue',
                sortable: true,
                width: 250
            },
            {
                headerName: 'configNameTh',
                field: 'configNameTh',
                sortable: true,
                width: 250
            },
            {
                headerName: 'configNameEn',
                field: 'configNameEn',
                sortable: true,
                width: 250
            },
            {
                headerName: 'configStatus',
                field: 'configStatus',
                sortable: true,
                width: 130
            },
            {
                headerName: 'configEffectiveDateTime',
                valueGetter: (params) => params.data.configEffectiveDateTime ? moment(params.data.configEffectiveDateTime).format('DD/MM/YYYY HH:mm:ss') : "",
                sortable: true,
                width: 250
            },
            {
                headerName: 'configExpireDateTime',
                valueGetter: (params) => params.data.configExpireDateTime ? moment(params.data.configExpireDateTime).format('DD/MM/YYYY HH:mm:ss') : "",
                sortable: true,
                width: 250
            },
        ]
    }

    async onClickSearch() {
        this.pageNumber = 1;
        if (this.searchOptions.configCode == '') {
            this.alertService.alertError('User Name must be string');
            return;
        }
        this.configurationList = []
        this.getWebConfigList(this.searchOptions.configCode);
    }

    onClickClear() {
        this.pageNumber = 1
        this.searchOptions = {
            configCode: ''
        };
        this.configurationList = []
        this.getWebConfigList('');
    }

    onClickEditConfig(config: Configuration) {
        this.isValidAbo = true;
        this.configuration = config;
        this.selectedStatus = config.configStatus || "";
        this.configEffectiveDatetime = config.configEffectiveDateTime ? moment(config.configEffectiveDateTime).toDate() : null;
        this.configExpireDatetime = config.configExpireDateTime ? moment(config.configExpireDateTime).toDate() : null;
        this.asingRoleConditionModal = true
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;

    }

    async gotoPage(page: any) {
        if (page > 0 && page <= this.pageInfo.allpage) {
            this.pageInfo.page = page;
            await this.getWebConfigList(this.searchOptions.configCode);
        }
    }

    getWebConfigList(textSearch: string) {
        this.spinner.show();
        this.configurationService.getConfigs(textSearch, String(this.pageInfo.page), String(this.pageInfo.rowPerPage)).subscribe((res: any) => {
            if (res.success && res.data.length > 0) {
                this.configurationList = res.data as Configuration[];
                this.pageInfo.page = Number(res.pageinfo.page);
                this.pageInfo.rowPerPage = Number(res.pageinfo.rowPerPage)
                this.pageInfo.totalRecord = Number(res.pageinfo.totalRecord)
                this.pageInfo.allpage = Math.ceil(this.pageInfo.totalRecord / this.pageInfo.rowPerPage);
            }
                this.spinner.hide();
        
        }, (error: any) => {
            this.spinner.hide();
            this.alertService.alertError(error?.error?.error?.errorMsg);
            
        }
        )
    }

    async saveCondition() {


        if (this.configuration.configValue == undefined || this.configuration.configValue == null || this.configuration.configValue.trim() == "") {
            this.alertService.alertError('Require configValue');
            return;
        }

        if(this.configuration.configGroup == 'SENDMAIL'){
            
            const listMail = this.configuration.configValue.split(',');
            for (const mail of listMail) {
              const regexMail = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$', 'g');
              if(!regexMail.exec(mail)){
                 this.alertService.alertError('ConfigValue invalid email format "'+ mail +'"');
                 return;
              }
            }
          }

          if(this.configuration.configGroup == 'ACCEPT'){
            const regex = new RegExp(/^\d+$/);
              if(!regex.exec(this.configuration.configValue)){
                this.alertService.alertError('ConfigValue invalid integer "'+ this.configuration.configValue +'"');
              return;
              }
          }

        if (this.selectedStatus == undefined || this.selectedStatus == null || this.selectedStatus == "") {
            this.alertService.alertError('Require status');
            return;
        }

        if(moment(this.configExpireDatetime).isSameOrBefore(this.configEffectiveDatetime)){
            this.alertService.alertError('ExpireDateTime must be greater than EffectiveDatetime');
            return;
        }

        if (this.configEffectiveDatetime == undefined || this.configEffectiveDatetime == null) {
            this.alertService.alertError('Require Effective Datetime');
            return;
        }

       

        this.spinner.show();
        this.configuration.configStatus = this.selectedStatus;
        this.configuration.configEffectiveDateTime =this.configEffectiveDatetime ? moment(this.configEffectiveDatetime).format():'';
        this.configuration.configExpireDateTime =this.configExpireDatetime ? moment(this.configExpireDatetime).format():'';

        this.configurationService.updateConfig(this.configuration).subscribe({
            next: async (res: any) => {
                if (res.success && res.data) {
                    const list = this.configurationList;
                    this.configurationList = []
                    this.closeAsingRoleConditionModal()
                    this.spinner.hide();
                    setTimeout(() => {
                        this.alertService.alertSuccess('Update config successfully');
                    }, 500);


                    this.getWebConfigList('');

                }
            },
            error: (error: any) => {
                this.spinner.hide();
                this.alertService.alertError(error?.error?.error?.errorMsg);
            }
        })
    }

    closeAsingRoleConditionModal() {
        this.asingRoleConditionModal = false
        this.configuration = {}
        this.isValidAbo = false;
    }



}
