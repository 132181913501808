<c-container>
    <c-row>
        <c-col class="col-8 mx-auto">
            <form cForm>
                <c-row class="mb-4">
                    <label class="col-3 my-auto" cCol cLabel for="inputEmail-1">ABO</label>
                    <c-col>
                        <select name="search_abo_type" class="form-select" aria-label="search_abo_type"
                            [(ngModel)]="searchFormModel.ABOType" cSelect>
                            <option *ngFor="let option of ABOTypeOptionList" value="{{ option.value }}">
                                {{ option.label }}
                            </option>
                        </select>
                    </c-col>
                    <c-col>
                        <input aria-label="search_abo_number" placeholder="ABO Number" type="text"
                            name="search_abo_number" id="search_abo_number" [(ngModel)]="searchFormModel.ABONumber"
                            cFormControl [disabled]="searchFormModel.ABOType == '' " />
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <label class="col-3 my-auto" cCol cLabel for="inputEmail-1">Order Number</label>
                    <c-col>
                        <c-form-check>
                            <input aria-label="search_orderNumber" type="text" name="search_orderNumber"
                                id="search_orderNumber" [(ngModel)]="searchFormModel.OrderNumber" cFormControl />
                        </c-form-check>
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <label class="col-3 my-auto" cCol cLabel for="inputEmail-1">Random Type</label>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_type_regis" type="checkbox"
                                [(ngModel)]="ramdomTypeRegis" (change)="onCheckboxTypeChange('registration', $event)"
                                checked />
                            <label cFormCheckLabel for="checkOne">Registration</label>
                        </c-form-check>
                    </c-col>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_type_check_out" type="checkbox"
                                [(ngModel)]="ramdomTypeCheckout"
                                (change)="onCheckboxTypeChange('public_checkout', $event)" checked />
                            <label cFormCheckLabel for="checkOne">Check out</label>
                        </c-form-check>
                    </c-col>
                </c-row>
                <!-- <c-row class="mb-4">
          <label class="col-3 my-auto" cCol cLabel for="inputEmail-1"
            >Period</label
          >
          <c-col class="col-auto">
            <select
              name="search_period"
              class="form-select"
              aria-label="search_period"
              [(ngModel)]="searchFormModel.Period"
              cSelect
            >
              <option>Select Period</option>
              <option
                *ngFor="let option of PeriodOptionList"
                value="{{ option.value }}"
              >
                {{ option.label }}
              </option>
            </select>
          </c-col>
        </c-row> -->
                <c-row class="mb-4">
                    <label class="col-3 my-auto" cCol cLabel for="inputEmail-1">Ramdom Date</label>
                    <c-col>
                        <div class="input-group">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dpfrom"
                                [(ngModel)]="searchFormModel.RandomDateFrom" ngbDatepicker #dfrom="ngbDatepicker"
                                readonly="true" />
                            <button class="btn btn-outline-secondary calendar" (click)="dfrom.toggle()" type="button">
                                <svg cIcon name="cilCalendar" size="xl" title="Calendar"></svg>
                            </button>
                        </div>
                    </c-col>
                    <c-col>
                        <div class="input-group">
                            <input class="form-control" placeholder="yyyy-mm-dd" name="dpto"
                                [(ngModel)]="searchFormModel.RandomDateTo" ngbDatepicker #dto="ngbDatepicker"
                                readonly="true" />
                            <button class="btn btn-outline-secondary calendar" (click)="dto.toggle()" type="button">
                                <svg cIcon name="cilCalendar" size="xl" title="Calendar"></svg>
                            </button>
                        </div>
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <label class="col-3 my-auto" cCol cLabel for="inputEmail-1">Request Status</label>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_request_new" type="checkbox"
                                [(ngModel)]="requestStatusNew"
                                (change)="onCheckboxRequestStatusChange('new', $event)" />
                            <label cFormCheckLabel for="checkOne">New</label>
                        </c-form-check>
                    </c-col>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_request_confirm" type="checkbox"
                                [(ngModel)]="requestStatusConfirm"
                                (change)="onCheckboxRequestStatusChange('confirm', $event)" />
                            <label cFormCheckLabel for="checkOne">Confirm</label>
                        </c-form-check>
                    </c-col>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_request_confirm" type="checkbox"
                                [(ngModel)]="requestStatusNotConfirm"
                                (change)="onCheckboxRequestStatusChange('notpaid', $event)" />
                            <label cFormCheckLabel for="checkOne">Not Confirm</label>
                        </c-form-check>
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <label class="col-3 my-auto" cCol cLabel for="inputEmail-1">Accept Status</label>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_status_waiting" type="checkbox"
                                [(ngModel)]="randomStatusWaiting"
                                (change)="onCheckboxAcceptStatusChange('waiting', $event)"
                                [disabled]='disabledRandomStatus' />
                            <label cFormCheckLabel for="checkOne">Waiting</label>
                        </c-form-check>
                    </c-col>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_status_accepted" type="checkbox"
                                [(ngModel)]="randomStatusAccept"
                                (change)="onCheckboxAcceptStatusChange('accepted', $event)"
                                [disabled]='disabledRandomStatus' />
                            <label cFormCheckLabel for="checkOne">Accepted</label>
                        </c-form-check>
                    </c-col>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_status_a_accepted" type="checkbox"
                                [(ngModel)]="randomStatusAuto"
                                (change)="onCheckboxAcceptStatusChange('auto-accepted', $event)"
                                [disabled]='disabledRandomStatus' />
                            <label cFormCheckLabel for="checkOne">Auto-Accepted</label>
                        </c-form-check>
                    </c-col>
                    <c-col>
                        <c-form-check>
                            <input cFormCheckInput name="search_status_n_accepted" type="checkbox"
                                [(ngModel)]="randomStatusNotAccept"
                                (change)="onCheckboxAcceptStatusChange('not-accepted', $event)"
                                [disabled]='disabledRandomStatus' />
                            <label cFormCheckLabel for="checkOne">Not-Accepted</label>
                        </c-form-check>
                    </c-col>
                </c-row>
                <c-row class="mb-4">
                    <c-col [offset]="3">
                        <c-row>
                            <c-col>
                                <button cButton color="primary" (click)="search()" class="w-100 text-white">
                                    Search
                                </button>
                            </c-col>
                            <c-col>
                                <button cButton color="light" class="w-100" (click)="clear()">
                                    Clear
                                </button>
                            </c-col>
                        </c-row>
                    </c-col>
                </c-row>
            </form>
        </c-col>
    </c-row>
</c-container>

<c-container>
    <c-row>
        <c-col>
            <span class="badge bg-info fs-5">{{ resultRecord }}</span>
            &nbsp;
            &nbsp;
            <span class="badge bg-warning fs-5">{{ resultText }}</span>
            <br>
            <br>
        </c-col>
    </c-row>
    <c-row>
        <c-col>
            <ag-grid-angular style="width: 100%; height: 50vh" class="ag-theme-alpine" [rowData]="searchRowData"
                [columnDefs]="searchColumnDefs" (gridReady)="onSearchGridReady($event)"
                [frameworkComponents]="frameworkComponents" [pagination]="true"
                [paginationPageSize]="paginationPageSize" [columnHoverHighlight]="false"
                [enableCellTextSelection]="true">
            </ag-grid-angular>
        </c-col>
    </c-row>
    <c-row>
        <c-col>
            <span class="badge bg-dark my-1 mt-2"> ตัวอักษร สีดำ : รอ confirm paid downline </span>
            <br />
            <span class="badge bg-primary my-1"> ตัวอักษร สีน้ำเงิน : รอ upline accept </span>
            <br />
            <span class="badge bg-secondary my-1"> ตัวอักษร สีเทา : สถานะ not accept </span>
            <br />
            <span class="badge bg-success my-1"> ตัวอักษร สีเขียว : สถานะ auto accept downline </span>
        </c-col>
    </c-row>
</c-container>

<!-- random modal -->
<div class="modal fade" id="random_modal" tabindex="-1" aria-labelledby="Random Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ randomFormModel.aboNumber }}</h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="table_data_modal" aria-hidden="true">
                    <form cForm>
                        <c-row>
                            <label cCol cLabel="col" class="col-4 text-end" for="add_role_name"> Retry Random :</label>
                            <label cCol cLabel="col" class="col" for="add_role_name"> {{ randomFormModel.retryRandomShow
                                }} </label>
                        </c-row>
                        <c-row>
                            <label cCol cLabel="col" class="col-4 text-end" for="add_role_status"> New ABO :</label>
                            <label cCol cLabel="col" class="col" for="add_role_status"> {{ randomFormModel.randomFor }}
                            </label>
                        </c-row>
                        <c-row>
                            <label cCol cLabel="col" class="col-4 text-end" for="add_role_status"> Random Type :</label>
                            <label cCol cLabel="col" class="col" for="add_role_status"> {{ randomFormModel.randomType }}
                            </label>
                        </c-row>
                        <c-row>
                            <label cCol cLabel="col" class="col-4 text-end" for="add_role_status"> Province :</label>
                            <label cCol cLabel="col" class="col" for="add_role_status"> {{ randomFormModel.province }}
                            </label>
                        </c-row>
                        <c-row>
                            <label cCol cLabel="col" class="col-4 text-end" for="add_role_status"> PostCode :</label>
                            <label cCol cLabel="col" class="col" for="add_role_status"> {{ randomFormModel.postCode }}
                            </label>
                        </c-row>
                        <c-row>
                            <label cCol cLabel="col" class="col-4 text-end" for="add_role_status"> Result :</label>
                            <label cCol cLabel="col" class="col" for="add_role_status"> {{ randomFormModel.result }}
                            </label>
                        </c-row>
                        <c-row>
                            <label cCol cLabel="col" class="col-4 text-end" for="add_role_status"> Result Description
                                :</label>
                            <label cCol cLabel="col" class="col" for="add_role_status"> {{
                                randomFormModel.resultDescription }} </label>
                        </c-row>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <input (click)="goToRandom()" name="btn_random" cButton class="btn btn-success text-white px-4 mx-2"
                    type="button" value="Random" [disabled]="showBtnSMS" />
                <ng-template [ngIf]="showBtnSMS">
                    <input (click)="sendSMS()" name="btn_sms" cButton class="btn btn-warning text-white px-4 mx-2"
                        type="button" value="SMS" />
                </ng-template>

                <!-- <button
                        type="button"
                        class="btn btn-success text-white"
                        data-dismiss="modal"
                        (click)="goToRandom()"
                    >
                        Random
                    </button> -->
                <button type="button" class="btn btn-secondary px-4 mx-2" data-dismiss="modal" (click)="search()">
                    Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="history_modal" tabindex="-1" aria-labelledby="History Modal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"></h5>
                <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="table_data_modal" aria-hidden="true">
                    <c-row>
                        <c-col class="col">
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Random ID </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.ramdomId }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Random type </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.randomType }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Random Status </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.randomStatus }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> New ABO </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.randomFor }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Order No. </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.orderNumber }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Paid Status </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.paidStatus }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Province </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.province }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Postcode </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.postCode }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Cnt. Creadit </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.flagCntCreditRandom }}
                                </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"> Ref. Request </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.refRequest }} </label>
                            </c-row>
                            <c-row>
                                <label cLabel="col" class="col-4 fw-bold text-end"
                                    [ngStyle]="{'color': historyrandomFormModelTab1.pureAcceptStatus == null ? '#0000CD' : (historyrandomFormModelTab1.pureAcceptStatus == 'notaccept' ? '#A9A9A9' : (historyrandomFormModelTab1.pureAcceptStatus == 'accept' || historyrandomFormModelTab1.pureAcceptStatus == 'autoaccept' ? '#008000' : null))}">
                                    Accept Status </label>
                                <label cLabel="col" class="col"
                                    [ngStyle]="{'color': historyrandomFormModelTab1.pureAcceptStatus == null ? '#0000CD' : (historyrandomFormModelTab1.pureAcceptStatus == 'notaccept' ? '#A9A9A9' : (historyrandomFormModelTab1.pureAcceptStatus == 'accept' || historyrandomFormModelTab1.pureAcceptStatus == 'autoaccept' ? '#008000' : null))}">
                                    {{ historyrandomFormModelTab1.acceptStatus }}
                                </label>
                            </c-row>
                            <c-row class="mb-6">
                                <label cLabel="col" class="col-4 fw-bold text-end"> Accept Upline </label>
                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.acceptUpline }} </label>
                            </c-row>
                        </c-col>
                        <c-col class="col">
                            <c-nav variant="tabs">
                                <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="0" cNavLink
                                    [hidden]="showtab1">1st</a>
                                <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="1" cNavLink
                                    [hidden]="showtab2">2nd </a>
                                <a [cTabContent]="tabContent" [routerLink] [tabPaneIdx]="2" cNavLink
                                    [hidden]="showtab3">3rd</a>
                            </c-nav>
                            <c-tab-content #tabContent="cTabContent" [activeTabPaneIdx]="0">
                                <c-tab-pane class="p-3 border border-1 border-top-0">
                                    <form cForm>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> RandomSeqId </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.randomSeqId
                                                }} </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Pool ABO </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.aboNumber }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Random From </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.randomFrom }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Send SMS </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.sendSMS }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> SMS ShortLink </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.shortLink }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"
                                                [ngStyle]="{'color': historyrandomFormModelTab1.pureStatus == null ? '#0000CD' : (historyrandomFormModelTab1.pureStatus == 'notaccept' ? '#A9A9A9' : (historyrandomFormModelTab1.pureStatus == 'accept' || historyrandomFormModelTab1.pureStatus == 'autoaccept' ? '#008000' : null))}">
                                                Accept Status </label>
                                            <label cLabel="col" class="col"
                                                [ngStyle]="{'color': historyrandomFormModelTab1.pureStatus == null ? '#0000CD' : (historyrandomFormModelTab1.pureStatus == 'notaccept' ? '#A9A9A9' : (historyrandomFormModelTab1.pureStatus == 'accept' || historyrandomFormModelTab1.pureStatus == 'autoaccept' ? '#008000' : null))}">
                                                {{ historyrandomFormModelTab1.status }}
                                            </label>
                                        </c-row>
                                        <ng-template [ngIf]="historyrandomFormModelTab1.pureStatus == null">
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Countdown 48 Hrs.
                                                </label>
                                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab1.countdown
                                                    }}
                                                </label>
                                            </c-row>
                                        </ng-template>
                                        <ng-template [ngIf]="!historyrandomFormModelTab1.resendSMS">
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Result
                                                </label>
                                                <label cLabel="col" class="col"> {{ reSendSMSResult }}
                                                </label>
                                            </c-row>
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Result Description
                                                </label>
                                                <label cLabel="col" class="col"> {{ reSendSMSDescription }}
                                                </label>
                                            </c-row>
                                        </ng-template>
                                        <c-row>
                                            <c-col class="col text-center">
                                                <!-- <button type="button" class="btn"
                                                    (click)="reSendSMS(historyrandomFormModelTab1.ramdomId,historyrandomFormModelTab1.aboNumber,historyrandomFormModelTab1.randomSeqId)"
                                                    [hidden]="historyrandomFormModelTab1.resendSMS || (historyrandomFormModelTab1.randomType != 'registration')">
                                                    Send SMS ShortLink to PoolABO
                                                </button> -->
                                                <input
                                                    (click)="reSendSMS(historyrandomFormModelTab1.ramdomId,historyrandomFormModelTab1.aboNumber,historyrandomFormModelTab1.randomSeqId)"
                                                    [hidden]="historyrandomFormModelTab1.resendSMS || (historyrandomFormModelTab1.randomType != 'registration')"
                                                    cButton class="btn btn-warning text-white px-4 mx-2" type="button"
                                                    value="Send SMS ShortLink to PoolABO" />
                                            </c-col>
                                        </c-row>
                                    </form>
                                </c-tab-pane>
                                <c-tab-pane class="p-3 border border-1 border-top-0">
                                    <form cForm>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> RandomSeqId </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab2.randomSeqId
                                                }} </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Pool ABO </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab2.aboNumber }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Random From </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab2.randomFrom }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Send SMS </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab2.sendSMS }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> SMS ShortLink </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab2.shortLink }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"
                                                [ngStyle]="{'color': historyrandomFormModelTab2.pureStatus == null ? '#0000CD' : (historyrandomFormModelTab2.pureStatus == 'notaccept' ? '#A9A9A9' : (historyrandomFormModelTab2.pureStatus == 'accept' || historyrandomFormModelTab2.pureStatus == 'autoaccept' ? '#008000' : null))}">
                                                Accept Status </label>
                                            <label cLabel="col" class="col"
                                                [ngStyle]="{'color': historyrandomFormModelTab2.pureStatus == null ? '#0000CD' : (historyrandomFormModelTab2.pureStatus == 'notaccept' ? '#A9A9A9' : (historyrandomFormModelTab2.pureStatus == 'accept' || historyrandomFormModelTab2.pureStatus == 'autoaccept' ? '#008000' : null))}">
                                                {{ historyrandomFormModelTab2.status }}
                                            </label>
                                        </c-row>
                                        <ng-template [ngIf]="historyrandomFormModelTab2.pureStatus == null">
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Countdown 48 Hrs.
                                                </label>
                                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab2.countdown
                                                    }}
                                                </label>
                                            </c-row>
                                        </ng-template>
                                        <ng-template [ngIf]="!historyrandomFormModelTab2.resendSMS">
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Result </label>
                                                <label cLabel="col" class="col"> {{ reSendSMSResult }} </label>
                                            </c-row>
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Result Description
                                                </label>
                                                <label cLabel="col" class="col"> {{ reSendSMSDescription }} </label>
                                            </c-row>
                                        </ng-template>
                                        <!-- <button type="button" class="btn btn-secondary" color="primary"
                                            (click)="reSendSMS(historyrandomFormModelTab1.ramdomId,historyrandomFormModelTab2.aboNumber,historyrandomFormModelTab2.randomSeqId)"
                                            [hidden]="historyrandomFormModelTab2.resendSMS  || (historyrandomFormModelTab1.randomType != 'registration')">
                                            Send SMS ShortLink to PoolABO
                                        </button> -->
                                        <input
                                            (click)="reSendSMS(historyrandomFormModelTab1.ramdomId,historyrandomFormModelTab2.aboNumber,historyrandomFormModelTab2.randomSeqId)"
                                            [hidden]="historyrandomFormModelTab2.resendSMS  || (historyrandomFormModelTab1.randomType != 'registration')"
                                            cButton class="btn btn-warning text-white px-4 mx-2" type="button"
                                            value="Send SMS ShortLink to PoolABO" />
                                    </form>
                                </c-tab-pane>
                                <c-tab-pane class="p-3 border border-1 border-top-0">
                                    <form cForm>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> RandomSeqId </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab3.randomSeqId
                                                }} </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Pool ABO </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab3.aboNumber }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Random From </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab3.randomFrom }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Send SMS </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab3.sendSMS }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> SMS ShortLink </label>
                                            <label cLabel="col" class="col"> {{ historyrandomFormModelTab3.shortLink }}
                                            </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"
                                                [ngStyle]="{'color': historyrandomFormModelTab3.pureStatus == null ? '#0000CD' : (historyrandomFormModelTab3.pureStatus == 'notaccept' ? '#A9A9A9' : (historyrandomFormModelTab3.pureStatus == 'accept' || historyrandomFormModelTab3.pureStatus == 'autoaccept' ? '#008000' : null))}">
                                                Accept Status </label>
                                            <label cLabel="col" class="col"
                                                [ngStyle]="{'color': historyrandomFormModelTab3.pureStatus == null ? '#0000CD' : (historyrandomFormModelTab3.pureStatus == 'notaccept' ? '#A9A9A9' : (historyrandomFormModelTab3.pureStatus == 'accept' || historyrandomFormModelTab3.pureStatus == 'autoaccept' ? '#008000' : null))}">
                                                {{ historyrandomFormModelTab3.status }}
                                            </label>
                                        </c-row>
                                        <ng-template [ngIf]="historyrandomFormModelTab3.pureStatus == null">
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Countdown 48 Hrs.
                                                </label>
                                                <label cLabel="col" class="col"> {{ historyrandomFormModelTab3.countdown
                                                    }}
                                                </label>
                                            </c-row>
                                        </ng-template>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Email Retry Random
                                            </label>
                                            <label cLabel="col" class="col"> {{
                                                historyrandomFormModelTab3.emailRetryRandom }} </label>
                                        </c-row>
                                        <c-row>
                                            <label cLabel="col" class="col-4 fw-bold text-end"> Email Last Ramdom
                                            </label>
                                            <label cLabel="col" class="col"> {{
                                                historyrandomFormModelTab3.emailLastRandom }} </label>
                                        </c-row>
                                        <ng-template [ngIf]="!historyrandomFormModelTab3.resendSMS">
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Result </label>
                                                <label cLabel="col" class="col"> {{ reSendSMSResult }} </label>
                                            </c-row>
                                            <c-row>
                                                <label cLabel="col" class="col-4 fw-bold text-end"> Result Description
                                                </label>
                                                <label cLabel="col" class="col"> {{ reSendSMSDescription }} </label>
                                            </c-row>
                                        </ng-template>
                                    </form>
                                    <c-row>
                                        <c-col class="col text-center">
                                            <!-- <button type="button" class="btn btn-primary" color="primary"
                                                (click)="reSendSMS(historyrandomFormModelTab1.ramdomId,historyrandomFormModelTab3.aboNumber,historyrandomFormModelTab3.randomSeqId)"
                                                [hidden]="historyrandomFormModelTab3.resendSMS || (historyrandomFormModelTab1.randomType != 'registration')">
                                                Send SMS ShortLink to PoolABO
                                            </button> -->
                                            <input
                                                (click)="reSendSMS(historyrandomFormModelTab1.ramdomId,historyrandomFormModelTab3.aboNumber,historyrandomFormModelTab3.randomSeqId)"
                                                [hidden]="historyrandomFormModelTab3.resendSMS || (historyrandomFormModelTab1.randomType != 'registration')"
                                                cButton class="btn btn-warning text-white px-4 mx-2" type="button"
                                                value="Send SMS ShortLink to PoolABO" />
                                        </c-col>
                                    </c-row>
                                </c-tab-pane>
                            </c-tab-content>
                        </c-col>
                    </c-row>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="search()"> Cancel
                </button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdOpacity="0.1" bdColor="rgba(51,51,51,0.5)" size="large" color="#fff" type="ball-clip-rotate"
    [fullScreen]="true">
    <p class="text-white">Loading...</p>
</ngx-spinner>